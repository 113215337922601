<template>
  <div class="table">
    <div
      v-for="(advantage, index) in advantages"
      :key="index"
      class="element"
    >
      <span class="element__name">
        {{ advantage.name }}
      </span>

      <div class="sep" />

      <div class="element__values">
        <span
          v-for="(value, valueIndex) in advantage.values"
          :key="valueIndex"
          class="element__value"
        >
          <a
            v-if="advantage.link"
            :href="advantage.link"
          >
            {{ value }}
          </a>
          <span v-else>
            {{ value }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

const advantages = computed(() => [
  {
    name: t("advantagesSection.table.7.name"),
    values: [t("advantagesSection.table.7.value")]
  },
  {
    name: t("advantagesSection.table.8.name"),
    values: [t("advantagesSection.table.8.value")]
  },
  {
    name: t("advantagesSection.table.9.name"),
    values: [t("advantagesSection.table.9.value")]
  },
  {
    name: t("advantagesSection.table.10.name"),
    values: [t("advantagesSection.table.10.value")]
  },
  {
    name: t("advantagesSection.table.11.name"),
    values: [t("advantagesSection.table.11.value")]
  },
  {
    name: t("advantagesSection.table.12.name"),
    values: [t("advantagesSection.table.12.value")]
  },
  {
    name: t("advantagesSection.table.13.name"),
    values: [t("advantagesSection.table.13.value")]
  },
  {
    name: t("advantagesSection.table.14.name"),
    values: [t("advantagesSection.table.14.value")]
  },
  {
    name: t("advantagesSection.table.15.name"),
    values: [t("advantagesSection.table.15.value")]
  },
  {
    name: t("advantagesSection.table.16.name"),
    values: [t("advantagesSection.table.16.value")]
  }
]);

</script>

<style scoped lang="scss">
.element {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  align-items: flex-end;

  .sep {
    height: 2px;
    flex: 1;
    border-bottom: 1px dashed #626262;
    margin: 0px 20px 4px 20px;
    opacity: 0.5;
  }

  &__name {
    color: #1F3A43;
    font-family: Inter SemiBold;
    font-size: 22px;
    line-height: 23px; /* 104.545% */

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }

  &__value {
    color: #1F3A43;
    font-family: Inter;
    font-size: 22px;
    line-height: 23px; /* 104.545% */

    text-align: end;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    a {
      color: #2ABA7C;
      text-decoration-line: underline;
    }
  }

  &__values {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}
</style>