<template>
  <div class="wrapper">
    <div
      class="page-btn"
      @click="goBack"
    >
      &lt;
    </div>
    <div
      v-for="(el, index) of viewState"
      :key="index"
      class="page-btn"
      :active="el === activePage"
      :zero="el === 0"
      @click="setActivePage(el)"
    >
      {{ el === 0 ? "..." : el }}
    </div>
    <div
      class="page-btn"
      @click="goForward"
    >
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
const props = defineProps({
  totalItems: {
    type: Number,
    required: true
  },
  itemsPerPage: {
    type: Number,
    default: 30
  }
});
const emit = defineEmits(["changePage"]);

const pageCount = computed(() => {
  return Math.ceil(props.totalItems / props.itemsPerPage);
});
let activePage = ref(1);
const viewState = computed(() => {
  if (pageCount.value < 7) {
    let ret = [];
    for (let i = 0; i < pageCount.value; i++) {
      ret.push(i + 1);
    }
    return ret;
  }

  if (activePage.value < 4) {
    return [1, 2, 3, 0, pageCount.value];
  }

  if (pageCount.value - activePage.value < 3) {
    return [1, 0, pageCount.value - 2, pageCount.value - 1, pageCount.value];
  }

  return [
    1,
    0,
    activePage.value - 1,
    activePage.value,
    activePage.value + 1,
    0,
    pageCount.value
  ];
});

const setActivePage = el => {
  activePage.value = el === 0 ? activePage.value : el;
  emit("changePage", activePage.value);
};

const goForward = () => {
  activePage =
    activePage.value === pageCount.value ? pageCount.value : activePage.value + 1;
  emit("changePage", activePage.value);
};

const goBack = () => {
  activePage = activePage.value = activePage.value === 1 ? 1 : activePage.value - 1;
  emit("changePage", activePage.value);
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  gap: 15px;
}
.page-btn {
  width: 51px;
  height: 51px;
  background-color: white;
  color: #5f989c;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8dd0d6;
  border-radius: 10px;
  cursor: pointer;
  &[active="true"] {
    color: white;
    background-color: #2aba7c;
  }
  &[zero="true"] {
    border: none;
    width: 12px;
    cursor: default;
  }
}
</style>
