<template>
  <div class="back">
    <div class="block">
      <AuInput
        v-model="username"
        :placeholder="$t('login.email')"
        icon="email"
      />
      <AuInput
        v-if="!recovery"
        v-model="password"
        type="Password"
        :placeholder="$t('login.password')"
        icon="password"
        password-icon-type="light"
      />
      <div class="block-down">
        <div class="down-left">
          <div
            class="register"
            @click="goToRegister"
          >
            {{ $t("register.title") }}
          </div>
          <div
            v-if="!recovery"
            class="register"
            @click="recovery = true"
          >
            {{ $t("login.recovery") }}
          </div>
          <div
            v-if="recovery"
            class="register"
            @click="recovery = false"
          >
            {{ $t("emailVerify.login") }}
          </div>
        </div>
        <div class="down-right">
          <AuButton
            v-if="!recovery"
            center
            padding="22px 65px"
            @click="logIn"
          >
            {{ $t("login.button") }}
          </AuButton>
          <AuButton
            v-if="recovery"
            center
            padding="22px 65px"
            @click="sendRecovery"
          >
            {{ $t("login.recoveryBtn") }}
          </AuButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Trans from "@/localization/translation";
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
      recovery: false
    };
  },
  methods: {
    async logIn() {
      const data = {
        email: this.username,
        password: this.password
      };
      try {
        const resp = await this.$auth.login({
          data,
          staySignedIn: true,
          fetchUser: false,
          url: "/api/users/login"
        });
        this.$auth.remember(JSON.stringify({
          id: resp.data.data.id,
          username: this.username,
          token: resp.data.Authorization
        }));
        this.$toast.success(this.$t("login.success"));
        this.disablePoptin();
        this.$router.push({ name: "dashboard" });
        if (window?.carrotquest) {
          window.carrotquest.track("$authorized", { "$email": this.username });
          window.carrotquest.identify([
            { op: "update_or_create", key: "$email", value: this.username }
          ]);
        }
        else {
          console.error("carrot error");
        }
      }
      catch (e) {
        console.log(e.response.data.errors);
        if (e.response.data.errors == "User not confirmed!") {
          this.$toast.error(this.$t("login.errors.notConfirm"));
        }
        if (e.response.data.errors == "User not found!") {
          this.$toast.error(this.$t("login.errors.notFound"));
        }
        if (e.response.data.errors == "Invalid credentials!") {
          this.$toast.error(this.$t("login.errors.notCredentials"));
        }
        else {
          this.$toast.error(this.$t("login.error"));
        }
      }
    },
    async sendRecovery() {
      const params = {
        email: this.username
      };
      try {
        await axios.post("/api/users/reset_password", params);
        this.$toast.success(this.$t("register.successRegister"));
        this.recovery = false;
      }
      catch (e) {
        console.log(e.response.data.errors);
        if (e.response.data.errors == "User not confirmed!") {
          this.$toast.error(this.$t("login.errors.notConfirm"));
        }
        if (e.response.data.errors == "User not found!") {
          this.$toast.error(this.$t("login.errors.notFound"));
        }
        if (e.response.data.errors == "Invalid credentials!") {
          this.$toast.error(this.$t("login.errors.notCredentials"));
        }
        else {
          this.$toast.error(this.$t("login.error"));
        }
      }
    },

    goToRegister() {
      this.$router.push({ name: "Register", params: { locale: Trans.currentLocale } });
    },

    disablePoptin() {
      document.cookie = "_no_poptin=true;max-age=3650";
    }
  }
};
</script>

<style lang="scss">
.block {
  .block-down {
    .down-right {
      @media screen and (max-width: 800px) {
        button.btn {
          width: 100%;

          padding: 14px 50px;

          .btn__text {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>


<style scoped lang="scss">
.back {
  background: #1F3A43;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 10px;
}
.block {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px
}
.login-title {
  color: white;
  font-weight: bold;
  font-size: 58px;
}

.register {
  margin: 13px 0;
  cursor: pointer;
  color: #2ABA7C;
  text-decoration: underline;

  font-size: 26px;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
}
.block-down {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.down-right {
  @media screen and (max-width: 800px) {
    width: 100%;

    display: flex;
    justify-content: center;
  }
}

.down-left {
  @media screen and (max-width: 800px) {
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 30px;

    text-align: center;
  }
}
</style>
