import ar from "./locales/ar.json";
import br from "./locales/br.json";
import cn from "./locales/cn.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import id from "./locales/id.json";
import it from "./locales/it.json";
import jp from "./locales/jp.json";
import kr from "./locales/kr.json";
import ru from "./locales/ru.json";
import tr from "./locales/tr.json";
import { createI18n } from "vue-i18n";

let langCookie = document.cookie.match(/lang=[a-zA-Z0-9]{1,}/);
let lang = null;
if (langCookie) {
  lang = langCookie[0].slice(5);
}
else {
  lang = "en";
}

const i18n = createI18n({
  locale: lang,
  fallbackLocale: lang,
  globalInjection: true,
  legacy: false,
  messages: {
    ru, en, br, cn, de, es, fr, it, jp, kr, ar, tr, id
  }
});

export default i18n;
