<template>
  <section class="products-section container">
    <div class="content-wrapper">
      <h1>{{ $t("productsSection.header") }}</h1>

      <div class="products-list">
        <Product
          v-for="(product, index) in products"
          :key="index"
          :product="product"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";

import Product from "@/components/Product.vue";

import { useI18n } from "vue-i18n";

const { t } = new useI18n();

const products = computed(() => [
  {
    name: t("productsSection.products.first.name"),
    text: t("productsSection.products.first.text"),
    more: t("productsSection.products.first.more"),
    img: "tokens"
  },
  {
    name: t("productsSection.products.second.name"),
    text: t("productsSection.products.second.text"),
    more: t("productsSection.products.second.more"),
    img: "pay"
  },
  {
    name: t("productsSection.products.third.name"),
    text: t("productsSection.products.third.text"),
    more: t("productsSection.products.third.more"),
    img: "capital"
  },
  {
    name: t("productsSection.products.fourth.name"),
    text: t("productsSection.products.fourth.text"),
    more: t("productsSection.products.fourth.more"),
    img: "market"
  },
  {
    name: t("productsSection.products.fifth.name"),
    text: t("productsSection.products.fifth.text"),
    more: t("productsSection.products.fifth.more"),
    img: "id"
  },
  {
    name: t("productsSection.products.sixth.name"),
    text: t("productsSection.products.sixth.text"),
    more: t("productsSection.products.sixth.more"),
    img: "gold"
  }
]);
</script>

<style lang="scss" scoped>
.content-wrapper {
  color: #1f3a43;

  h1 {
    max-width: 1206px;
    font-weight: bold;
    font-size: 68px;
    margin-bottom: 50px;

    @media screen and (max-width: 1200px) {
      font-size: 38px;
    }
  }
}

.products {
  &-section {
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: start;

    padding-top: 60px;
  }

  &-list {
    display: flex;
    column-gap: 31px;
    row-gap: 81px;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 700px) {
      gap: 25px;
    }
  }
}
</style>
