<template>
  {{ }}
  <div class="referral-main">
    <div class="logo">
      <div class="left">
        <div class="square">
          20%
        </div>
        <div class="referal">
          <div class="invited-text">
            {{ $t("referralLogo.invited") }}
          </div>
          <div class="referral-line">
            <img
              class="referral-arrow"
              src="/img/cabinet/arrow.svg"
            >
            <span
              class="referral-text"
              @click="goToRefLink"
            >
              {{ $t("referralLogo.link") }}
            </span>
            <SuccessMark
              v-show="copied"
              :show-animate="copied"
            />
            <img
              v-if="!copied"
              class="referral-copy"
              src="/img/cabinet/copy.svg"
              @click="copyRefLink"
            >
          </div>
          <div
            class="referral-text referral-link"
            @click="goToRefLink"
          >
            {{ refLink }}
          </div>
        </div>
      </div>
      <img
        class="people"
        src="/img/cabinet/people.png"
      >
    </div>
    <div class="referral-body">
      <div class="info">
        <div class="line" />

        <div class="info-text">
          {{ $t("referralLogo.logo1") }}
          <span class="info-bold">{{ $t("referralLogo.logoValue") }}</span>
          {{ $t("referralLogo.logo2") }}
        </div>
        <div class="info-social">
          <span>{{ $t("referralLogo.socials") }}</span>
          <div class="info-social__icons">
            <AuIcon :link="facebookLink">
              <img
                class="icon"
                src="/img/cabinet/facebook.svg"
                alt="facebook"
              >
            </AuIcon>
            <AuIcon :link="tLink">
              <img
                class="icon"
                src="/img/cabinet/telegram.svg"
                alt="viber"
              >
            </AuIcon>
            <AuIcon :link="xLink">
              <img
                class="icon"
                src="/img/cabinet/x.svg"
                alt="x"
              >
            </AuIcon>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-line">
          <span>{{ $t("referralLogo.hadTo") }}</span>
          <span class="panel-bold">{{ refViewCount }}</span>
        </div>
        <div class="panel-line">
          <span>{{ $t("referralLogo.registerCount") }}</span>
          <span class="panel-bold">{{ refRegCount }}</span>
        </div>
        <div class="panel-line">
          <span>{{ $t("referralLogo.payCount") }}</span>
          <span class="panel-bold">{{ refPayCount }}</span>
        </div>
        <div class="panel-line">
          <span>{{ $t("referralLogo.amount") }}</span>
          <span class="panel-bold">{{ refPayAmount }} AUT</span>
        </div>
        <div class="panel-line">
          <span>{{ $t("referralLogo.cashback") }}</span>
          <span class="panel-bold">{{ usdtCashbackPercent || 0 }}$</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import BigNumber from "bignumber.js";
import SuccessMark from "@/components/SuccessMark.vue";
import Trans from "@/localization/translation";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n({});

const props = defineProps({
  user: {
    type: Object,
    default: () => {}
  }
});

const refLink = computed(() => {
  return `https://aut.finance/${Trans.currentLocale}?ref=${props.user?.refId === undefined ? 0 : props.user.refId}`;
});

const tLink = computed(() => {
  return `https://t.me/share/url?url=${encodeURIComponent(refLink.value)}&text=${encodeURIComponent(t("referralSection.friends"))}`;
});

const xLink = computed(() => {
  return `https://twitter.com/share?url=${encodeURIComponent(refLink.value)}&text=${encodeURIComponent(t("referralSection.friends"))}`;
});

const facebookLink = computed(() => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(refLink.value)}&t=${encodeURIComponent(t("referralSection.friends"))}`;
});

const refViewCount = computed(() => {
  return BigNumber(_.get(props.user, "refViewCount", 0)).toFixed(0);
});
const refRegCount = computed(() => {
  return BigNumber(_.get(props.user, "refRegCount", 0)).toFixed(0);
});
const refPayCount = computed(() => {
  return BigNumber(_.get(props.user, "refPayCount", 0)).toFixed(0);
});
const refPayAmount = computed(() => {
  return BigNumber(_.get(props.user, "refPayAmount", 0)).toFixed(0);
});

const goToRefLink = () => {
  window.open(refLink.value, "_blank");
};

const copied = ref(false);
const copyRefLink = () => {
  navigator.clipboard.writeText(refLink.value).then(() => {
    copied.value = true;
    setTimeout(function() {
      copied.value = false;
    }, 1700);
  });
};

</script>

<style lang="scss">
.referral-main {
  width: 100%;

  .logo {
    width: 100%;
    height: 243px;
    border-radius: 10px;
    background: linear-gradient(91deg, #F7FFEE 0.68%, #D6E7DD 57.45%);
    box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;

    @media (max-width: 1600px) and (min-width: 801px) {
      height: 357px;
      margin-bottom: -157px;
    }

    @media (max-width: 800px) {
      height: 136px;
      border-radius: 0px;
      box-shadow: none;

      width: calc(100% + 20px);
      margin-left: -10px;
    }

    @media (max-width: 400px) {
      height: 126px;
    }
  }

  .left {
    display: flex;
    align-items: baseline;
    gap: 15px;

    @media (max-width: 800px) {
      flex-direction: column;
      gap: 5px;
    }
  }

  .square {
    width: 179px;
    height: 195px;
    background-color: #A031E3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 57px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 50px;

    @media (max-width: 1600px) and (min-width: 801px) {
      height: 110px;
      width: 119px;
      padding-bottom: 30px;

      font-size: 42px;
    }

    @media (max-width: 800px) {
      height: 60px;
      width: 103px;

      border-top-left-radius: 0px;
      border-bottom-right-radius: 30px;

      font-size: 30px;
    }
  }

  .referal {
    z-index: 10;
    @media (max-width: 1600px) and (min-width: 801px)  {
      align-self: self-start;
      margin-top: 40px;
    }

    @media (max-width: 800px) {
      padding-left: 10px;
    }
  }

  .invited-text {
    color: #A031E3;
    font-weight: 900;
    font-size: 2vw;

    font-family: Inter Black;

    @media (max-width: 1600px) {
      font-size: 28px;
    }

    @media (max-width: 800px) {
      font-size: 16px;
    }

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  .referral-text {
    color: #2ABA7C;
    font-weight: 500;
    font-size: 1.5vw;
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: 1600px) {
      font-size: 20px;
    }

    @media (max-width: 800px) {
      font-size: 12px;
    }
  }

  .referral-link {
    margin-top: 1vw;
    font-size: 1vw;

    @media (max-width: 1600px) {
      font-size: 14px;
    }

    @media (max-width: 800px) {
      margin-top: 0vw;

      font-size: 10px;
    }
  }

  .referral-line {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .referral-arrow {
    width: 26px;

    @media (max-width: 800px) {
      width: 13px;
    }
  }

  .referral-copy {
    cursor: pointer;

    @media (max-width: 1600px) {
      height: 44px;
    }

    @media (max-width: 800px) {
      height: 27px;
    }
  }

  .people {
    height: 243px;
    object-fit: contain;

    @media (max-width: 1600px) {
      height: 180px;
    }

    @media (max-width: 800px) {
      height: 136px;
    }

    @media (max-width: 400px) {
      height: 126px;
    }
  }
}

.referral-body {
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .info {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1600px) and (min-width: 801px) {
      margin-top: -115px;

      .line {
        border-bottom: #4FE9A6 solid 3px;
        width: 79px;

        margin-bottom: 20px;
      }
    }

    @media (max-width: 1600px) {
      .line {
        border-bottom: #4FE9A6 solid 3px;
        width: 79px;

        margin-top: 20px;
      }
    }
  }

  .info-text {
    color: #1F3A43;
    font-weight: 450;
    font-size: 23px;

    @media (max-width: 1600px) {
      font-size: 15px;
    }
  }

  .info-bold {
    font-weight: 600;
  }

  .info-social {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 26px;

    &__icons {
      display: flex;
      gap: 10px;
    }

    @media (max-width: 1600px) {
      flex-direction: column;
      align-items: flex-start;

      font-size: 18px;

      gap: 20px;
      margin-top: 10px;

      img {
        width: 42px;
      }
    }

    @media (max-width: 800px) {
      margin-bottom: 20px;

      img {
        width: 33px;
      }
    }
  }

  .panel-line {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px dashed black;
  }

  .panel-bold {
    font-weight: 600;
  }

  .panel-dot {
    overflow: hidden;
    padding: 0 1rem;
  }

  .panel {
    width: 50%;
    position: relative;
    top: -59px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 40px;
    background-color: white;
    border-radius: 15px;
    font-size: 18px;
    white-space: nowrap;

    @media screen and (max-width: 800px) {
      padding: 20px;
    }

    @media (max-width: 1600px) {
      font-size: 14px;
    }

    @media (max-width: 800px) {
      top: 0px;
      width: auto;

      box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;
    }
  }
}
</style>
