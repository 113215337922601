<template>
  <section class="section container clock-section">
    <div class="left-block">
      <span class="clock-title">
        {{ $t("clockSection.header") }}
      </span>
      <span class="clock-text">
        {{ text }}
      </span>
      <div class="block-info">
        <span
          class="info-text"
          :bold="el.isHeader"
          v-for="(el, index) of info" :key="index"
        >
          {{ (el.isHeader ? "- " : "") + el.name }}
        </span>
      </div>
    </div>

    <Clock
      :month="isRoundTwo ? 2 : 3"
      :day="isRoundTwo ? 31 : 30"
      @showModal="openModal"
    />
  </section>
</template>

<script setup>
import Clock from "@/components/ClockOld.vue";
import TokensBuy from "@/components/TokensBuy";
import TokensBuyOld from "@/components/TokenBuyOld";

import { computed, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";
import { useModal } from "vue-final-modal";

const groupAB = inject("groupAB");
const openModal = () => {
  const component = groupAB == 1 ? TokensBuy : TokensBuyOld;
  const { open, close } = useModal({
    component: component,
    attrs: {
      onClose() {
        close();
      }
    }
  });

  open();
};

const props = defineProps({
  showModal: {
    type: Number,
    default: 0
  }
});

const { t } = useI18n({});
const isRoundTwo = computed(() => {
  const compare = new Date(2024, 3, 1, 12, 0, 0);
  const now = new Date();
  return now < compare;
});
const text = computed(() => {
  return (isRoundTwo.value ? t("clockSection.textRoundTwo") : t("clockSection.textOpenSale")) +
    " " + t("clockSection.text2");
});
const info = computed(() => {
  return [
    { name: t("clockSection.info.startTitle"), isHeader: true },
    { name: t("clockSection.info.numberTitle"), isHeader: true },
    { name: isRoundTwo.value ? "November 21, 2023 (12:00 UTC)" :
      "April 01, 2024 (12:00 UTC)", isHeader: false },
    { name: t("clockSection.info.numberValue"), isHeader: false },
    { name: t("clockSection.info.endTitle"), isHeader: true },
    { name: t("clockSection.info.exchangeTitle"), isHeader: true },
    { name: isRoundTwo.value ? "March 31, 2024 (12:00 UTC)" :
      "April 30, 2024 (12:00 UTC)", isHeader: false },
    { name: isRoundTwo.value ? "1 AUT = 0.08 USDT" :
      "1 AUT = 0.1 USDT", isHeader: false },
    { name: t("clockSection.info.accesibleTitle"), isHeader: true },
    { name: t("clockSection.info.minimalTitle"), isHeader: true },
    { name: t("clockSection.info.accesibleValue"), isHeader: false },
    { name: t("clockSection.info.minimalValue"), isHeader: false }
  ];
});

onMounted(() => {
  if (props.showModal !== 0) {
    openModal(props.showModal);
  }
});
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  justify-content: space-between;

  &.clock-section {
    margin-top: 100px;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    @media screen and (max-width: 800px) {
      margin-top: -50px;
    }
  }
}

.left-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 80px;
  @media screen and (max-width: 1200px) {
    margin-top: 180px;
  }
}
.clock-title {
  font-size: 68px;
  color: #BCC4C7;
  @media screen and (max-width: 600px) {
    font-size: 38px;
  }
}
.clock-text {
  font-size: 28px;
  line-height: 38px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 28px;
  }
}
.block-info {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: repeat(6, 50px);
  @media screen and (max-width: 800px) {
    grid-template-rows: repeat(6, 30px);
  }
}
.info-text {
  font-size: 18px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
  &[bold=true] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
  }
}
</style>
