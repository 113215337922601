<template>
  <div class="top-main">
    <a
      class="link"
      href="/"
    >
      <img
        src="/img/cabinet/logo.svg"
        alt="logo"
      >
    </a>
    <div class="divide">
      <div>
        <div class="text">
          {{ $t("topMenu.balance") }}
        </div>
        <div class="title">
          {{ props.total }}
          <img
            src="/img/cabinet/aut.svg"
            alt="aut"
          >
          <span class="text">AUT</span>
        </div>
      </div>
      <div class="action-buttons-display">
        <AuButton
          padding="23px 30px"
          height="55px"
          font-size="18px"
          center
          @click="openModal"
        >
          {{ $t("topMenu.buy") }}
        </AuButton>
        <AuButton
          padding="23px 30px"
          height="55px"
          font-size="18px"
          center
          @click="openWithdraw"
        >
          {{ $t("topMenu.withdraw") }}
        </AuButton>
      </div>
      <div class="action-buttons-mobile">
        <button
          class="action-button"
          @click="openModal"
        >
          {{ $t("topMenu.buy") }}
        </button>
        <button
          class="action-button"
          @click="openWithdraw"
        >
          {{ $t("topMenu.withdraw") }}
        </button>
      </div>
    </div>
    <div class="divide divide-address">
      <div>
        <div class="text">
          {{ $t("topMenu.address") }}
        </div>
        <div class="title">
          {{ shortAdr }}
        </div>
      </div>
      <AuButton
        padding="23px 30px"
        height="55px"
        font-size="18px"
        center
        @click="goToSettings"
      >
        {{ changeButton }}
      </AuButton>
      <div
        v-if="showTriangle"
        class="triangle"
        @mouseenter="showMessage=true"
        @mouseleave="showMessage=false"
      >
        <img
          class="warning"
          src="/img/cabinet/triangle.svg"
        >
        <div
          v-if="showMessage"
          class="message"
        >
          {{ $t("topMenu.message") }}
        </div>
      </div>
    </div>
    <div
      class="divide divide-locale"
      :language="true"
    >
      <AuSelect
        css-vars="--width: 160px"
        class="locale"
        :value="currentLocale"
        :placeholder="currentLocale.toUpperCase()"
        :options="countryOptions"
        :image="true"
        @input="(v) => switchLanguage(v)"
      />
    </div>
    <div class="divide divide-person">
      <img
        src="/img/cabinet/person.svg"
        alt="person"
      >
      <span class="person">{{ userName }}</span>
      <AuButton
        height="55px"
        font-size="18px"
        padding="23px 30px"
        background-color="#00000000"
        text-color="#2ABA7C"
        center
        @click="logOut"
      >
        {{ $t("topMenu.exit") }}
        <img
          src="/img/cabinet/arrow.svg"
          alt="arrow"
        >
      </AuButton>
    </div>

    <div
      class="menu"
      @click="showMenu = true"
    >
      <img
        src="/img/cabinet/menu.svg"
        alt="person"
      >
    </div>

    <Menu
      v-if="showMenu"
      :address="address"
      :total="total"
      @close="showMenu=false"
    />
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import isTokenSaleClose from "@/help/sale";
import Trans from "@/localization/translation";

import Menu from "@/components/Menu.vue";
import TokenClose from "@/components/TokenClose";
import TokensBuy from "@/components/TokensBuy";
import TokensBuyOld from "@/components/TokenBuyOld";
import Withdraw from "@/components/Withdraw";

import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useModal } from "vue-final-modal";
import { useRouter } from "vue-router";

const { t } = new useI18n();

const props = defineProps({
  address: {
    type: String,
    default: ""
  },
  total: {
    type: String,
    default: ""
  }
});

const showMenu = ref(false);

const shortAdr = computed(() => {
  return props.address === "-" ?
    t("topMenu.addressAut") : props.address.slice(0, 5) + "..." + props.address.slice(-5);
});
const changeButton = computed(() => {
  return props.address === "-" ? t("topMenu.setAddress") : t("topMenu.change");
});

const supportedLocales = ref(Trans.supportedLocales);

const router = useRouter();

const goToSettings = () => {
  router.push({ name: "settings" });
};

const currentLocale = computed(() => {
  return Trans.currentLocale;
});
const countryOptions = computed(() => {
  return _.map(supportedLocales.value, l => {
    return {
      display: l.toUpperCase(),
      value: l,
      image: `/img/flags/${l}.svg`
    };
  });
});
document.title = t("title");

const switchLanguage = async newLocale => {
  await Trans.switchLanguage(newLocale);

  try {
    await router.replace({ params: { locale: newLocale } });
    document.title = t("title");
  }
  catch (e) {
    console.log(e);
    router.push("/");
  }
};

const userName = computed(() => {
  let ret = "";
  try {
    ret = JSON.parse(auth.remember());
  }
  catch (e) {
    return "";
  }
  return ret?.username ? ret.username : "";
});

const logOut = () => {
  auth.unremember();
  auth.logout();
  router.push({ name: "Main" });
};

const groupAB = inject("groupAB");
const openModal = () => {
  let component = groupAB == 1 ? TokensBuy : TokensBuyOld;
  component = isTokenSaleClose() ? TokenClose : component;
  const { open, close } = useModal({
    component: component,
    attrs: {
      initEmail: userName.value,
      initAddress: props.address,
      onClose() {
        close();
      }
    }
  });

  open();
};

const openWithdraw = () => {
  const { open, close } = useModal({
    component: Withdraw,
    attrs: {
      onClose() {
        close();
      }
    }
  });

  open();
};

const showTriangle = computed(() => {
  if (!isConnected.value || props.address === "-") {
    return false;
  }
  return !(metamaskAddress == props.address);
});

const isConnected = ref(false);
const showMessage = ref(false);
let metamaskAddress = null;
</script>

<style lang="scss">
.top-main {
  button.btn {
    @media (max-width: 1600px) {
      padding: 0px 22px !important;
      height: 35px !important;
      border-radius: 5px !important;

      .btn__text {
        font-size: 15px !important;
      }
    }
  }
}

.locale {
  .multiselect {
    border: none;
    &__select {
      right: 18px;
      top: 5px;
    }
    &__single {
      color: white;
      font-size: 20px;
      div {
        display: flex;
        align-items: center;
        .picture {
          margin-right: 10px;
          height: 28px;
        }
      }
    }
    &__input {
      font-size: 20px;
      padding-left: 53px;
      padding-top: 6px;
      color: transparent;
    }
    &__content {
      &-wrapper {
        top: 50px;
        left: 0;
      }
    }
    &__element {
      font-size: 20px;
      padding-left: 0;
      .picture {
        height: 28px;
      }
    }
    &__option {
      padding: 7px 22px;
    }
  }
}
</style>

<style lang="scss" scoped>
.top-main {
  width: 100%;
  height: 113px;
  background-color: #1F3A43;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;

  .link {
    @media (max-width: 1600px) {
      margin-left: 30px;
    }

    @media (max-width: 1000px) {
      margin-left: 20px;
    }

    img {
      @media (max-width: 1600px) {
        height: 25px;
      }

      @media (max-width: 800px) {
        height: 20px;
      }

      @media (max-width: 400px) {
        margin-left: -10px;
      }
    }
  }

  @media (max-width: 1600px) {
    height: 62px;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    height: 55px;
  }

  .menu {
    display: none;
    cursor: pointer;

    margin-right: 30px;
    margin-left: 10px;

    @media (max-width: 1600px) {
      display: block;
    }

    @media (max-width: 1000px) {
      margin-right: 20px;
    }
  }

  .divide {
    padding-left: 20px;
    border-left: 2px solid #305561;
    display: flex;
    align-items: center;
    gap: 30px;
    &[language="true"] {
      padding-left: 0;
    }

    @media (max-width: 1600px) {
      &.divide-address {
        display: none;
      }

      &.divide-locale {
        display: none;
      }

      &.divide-person {
        border-left: none;
        padding-left: 0px;

        gap: 5px;

        @media (max-width: 800px) {
          display: none;
        }

        .person {
          margin-right: 15px;
        }
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1600px) {
      font-size: 16px;
    }
  }

  .text {
    font-weight: 400;
    font-size: 20px;

    text-align: center;

    @media (max-width: 1600px) {
      font-size: 14px;
    }
  }

  .metamask-btn {
    width: 193px;
    height: 55px;
    padding-left: 10px;
    border-radius: 10px;
    background-color: #FFF3C9;
    color: #763D16;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &[disable="true"] {
      background-color: #cccccc;
      cursor: default;
    }
  }

  .person {
    color: #4FE9A6;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 1600px) {
      font-size: 15px;
    }
  }
}

.triangle {
  position: relative;
  .warning {
    width: 30px;
    height: 30px;
  }
  .message {
    z-index: 100;
    width: 250px;
    top: 30px;
    left: 30px;
    padding: 5px;
    position: absolute;
    border: 1px solid grey;
    background-color: rgba(0,0,0, 0.8);
    color: white;
    line-height: 1.5;
  }
}
.adr-message {
  font-size: 12px;
  color: #deebff;
}
.adr-change {
  color: #668c99;
  cursor: pointer;
}
.action-button {
  padding: 3px 10px;
  color: white;
  background-color: #2aba7c;
  border-radius: 10px;
  border: none;
  font-size: 12px;
}
.action-buttons-display {
  display: flex;
  gap: 20px;

  @media (max-width: 900px) and (min-width: 800px) {
    display: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
}
.action-buttons-mobile {
  display: none;
  flex-direction: column;
  gap: 7px;

  @media (max-width: 900px) and (min-width: 800px) {
    display: flex;
  }

  @media (max-width: 500px) {
    display: flex;

  }
}
</style>
