<template>
  <VueFinalModal>
    <div class="tc-main">
      <img
        class="tc-decoration"
        src="/img/modal/decoration-close.svg"
        alt=""
      >
      <img
        class="tc-close"
        src="/img/modal/close.svg"
        alt=""
        @click="$emit('close')"
      >
      <div class="tc-content">
        <div class="tc-text">
          <span class="tc-title">
            {{ $t("tokenClose.notPossible") }}
          </span>
          <span class="tc-subtitle">
            {{ $t("tokenClose.listing") + ` ${listing}` }}
          </span>
        </div>
        <CloseClock />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import CloseClock from "@/components/CloseClock";
import { computed } from "vue";
import { VueFinalModal } from "vue-final-modal";

const listing = computed(() => {
  return process.env.VUE_APP_LISTING;
});
</script>

<style scoped lang="scss">
.tc-main {
  width: 777px;
  height: 423px;
  background: #daedf3;

  border-radius: 30px;
  background: linear-gradient(256deg, #daedf3 -2.62%, #fefeff 77.25%, #fff 77.25%);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 600px;
  }
  @media screen and (max-width: 600px) {
    width: calc(100% - 80px);
  }
}
.tc-decoration {
  width: 100%;
  height: 100%;
  position: absolute;
}
.tc-close {
  position: absolute;
  right: -30px;
  top: -20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.tc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 10;
}
.tc-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.tc-title {
  font-size: 38px;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 28px;
  }
}
.tc-subtitle {
  font-size: 24px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}
</style>
