<template>
  <div
    :style="inputStyle"
    class="input-wrapper"
  >
    <div
      :type="type"
      class="input_block"
    >
      <img
        v-if="icon !== ''"
        :src="`/img/input-icons/${icon}.svg`"
        :type="type"
        class="input-icon"
        alt="no_img"
      >

      <input
        v-if="type === 'Text'"
        type="text"
        :width="width"
        :disabled="disabled"
        class="input"
        :placeholder="placeholder"
        :value="modelValue"
        @input="(e) => input(e.target.value)"
      >

      <input
        v-if="type === 'Password'"
        :type="showPassword ? 'text' : 'password'"
        :width="width"
        :disabled="disabled"
        class="input mask"
        :placeholder="placeholder"
        :value="modelValue"
        @input="(e) => input(e.target.value)"
      >

      <IMaskComponent
        v-if="type === 'Mask'"
        :model-value="modelValue"
        class="input"
        :mask="mask"
        :placeholder="placeholder"
        @update:modelValue="(e) => input(e)"
      />

      <textarea
        v-if="type === 'TextArea'"
        :style="textAreaStyle"
        class="text-area"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="(e) => input(e.target.value)"
      />

      <img
        v-if="type === 'Password'"
        :src="`/img/input-icons/eye-${passwordIconType}-${
          showPassword ? 'show' : 'hide'
        }.svg`"
        class="password-icon"
        alt="eye"
        @click="togglePassword"
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { IMaskComponent } from "vue-imask";

const props = defineProps({
  icon: {
    type: String,
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  modelValue: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "Text",
    validator: value => {
      let validationResult =
        ["Text", "Password", "Mask", "TextArea"].indexOf(value) !== -1;

      if (!validationResult) {
        console.error(value);
      }

      return validationResult;
    }
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  borderRadius: {
    type: String,
    default: "10"
  },
  width: {
    type: String,
    default: "100%"
  },
  textAreaHeigth: {
    type: Number,
    default: 253
  },
  color: {
    type: String,
    default: "white"
  },
  placeholderColor: {
    type: String,
    default: "#096f57"
  },
  mask: {
    type: [RegExp, Function],
    default: null
  },
  passwordIconType: {
    type: String,
    default: ""
  }
});

const showPassword = ref(false);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const emit = defineEmits(["update:modelValue"]);

const textAreaStyle = computed(() => {
  return `height: ${props.textAreaHeigth}px`;
});

const inputStyle = computed(() => {
  return {
    "--border-radius": props.borderRadius + "px",
    "--width": props.width,
    "--color": props.color,
    "--placeholder-color": props.placeholderColor,
    "--border-color": props.required ? "red" : "#4fe9a6"
  };
});
const input = value => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: var(--width);
}
.input {
  color: var(--color);
  width: 100%;
  padding: 22px 30px;
  font-size: 28px;
  background: transparent;
  border-radius: 10px;
  border: 2px solid var(--border-color);

  &::placeholder {
    color: var(--placeholder-color);
  }
}
.input_block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-radius: var(--border-radius);
  font-weight: 450;
  font-size: 13px;
  width: var(--width);
  position: relative;
  &:has(> input[disabled]) {
    border: 1px solid grey;
  }

  &[type="TextArea"] {
    .input-icon {
      top: 22px;
    }
  }
}

.password-icon {
  position: absolute;
  right: 30px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1500px) {
    right: 15px;
    height: 20px;
  }
}

.input-icon {
  position: absolute;
  left: 10px;
  @media screen and (max-width: 1200px) {
    left: 10px;
  }
  &:has(+ textarea) {
    top: 24px;
  }
  & + input {
    padding: 22px 30px 22px 79px;
    &:has(+ .password-icon) {
      padding: 22px 79px 22px 80px;
    }
    @media screen and (max-width: 1500px) {
      font-size: 15px;
      padding: 15px 15px 15px 50px;
      &:has(+ .password-icon) {
        padding: 15px 50px 15px 50px;
      }
    }
  }
  & + textarea {
    padding: 22px 30px 22px 79px;
    @media screen and (max-width: 1500px) {
      font-size: 15px;
      padding: 22px 15px 15px 50px;
    }
  }
}

.text-area {
  width: 100%;
  border-radius: 12px;
  padding-top: 6px;
  resize: none;
  border: 0;
  outline: none;
  padding: 23px 30px;
  font-size: 28px;
  background: transparent;
  border-radius: 10px;
  color: white;
  border: 2px solid #4fe9a6;
  :focus {
    outline: none;
  }
  &::placeholder {
    color: #096f57;
  }
}
</style>
