<template>
  <SpinnerLoader
    v-if="showLoader"
    color="#5AC396"
    size="400px"
  />
  <div
    v-else
    class="s-spread"
  >
    <div class="s-panel">
      <div class="s-header">
        {{ $t("bridge.step1.nickname") }}
      </div>
      <div class="s-body">
        <Popper
          class="s-full"
          :content="tonError"
          arrow
          placement="bottom"
          :show="showError && tonError !== ''"
        >
          <AuInput
            v-model="tonAddress"
            color="black"
          />
        </Popper>
      </div>
    </div>
    <AuButton
      center
      @click="goNext"
    >
      <span class="s-next-text">
        {{ $t("bridge.step1.getAdr") }}
      </span>
    </AuButton>
  </div>
</template>

<script setup>
import AuButton from "@/components/extended/AuButton";
import AuInput from "@/components/extended/AuInput";
import axios from "axios";
import SpinnerLoader from "@/loaders/progress-spinner";
import { computed, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";

const { t } = useI18n({});
const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});

const showLoader = ref(false);
const tonAddress = ref("");
const showError = ref(false);
let addressTo = "";
let isSuccess = false;

const tonError = computed(() => {
  return tonAddress.value?.length > 0 ? "" : t("bridge.step1.nicknameError");
});

const createTonPay = async () => {
  const params = {
    tonAddress: tonAddress.value,
    addressFrom: ""
  };
  try {
    addressTo = await axios.post("/api/ton_pays/create", params);
    isSuccess = true;
  }
  catch (e) {
    toast.error(t("bridge.step1.tonPayError"));
  }
};

const emit = defineEmits(["next"]);
const goNext = async () => {
  showError.value = true;
  if (tonError.value !== "") {
    return;
  }
  showLoader.value = true;
  await createTonPay();
  showLoader.value = false;
  emit("next", {
    addressTo: addressTo?.data?.address,
    isSuccess: isSuccess
  });
};
</script>

<style scoped lang="scss">
.s-panel {
  border-radius: 15px;
  background: #f7fbfd;
  box-shadow: 0 18px 33px 0 rgba(210, 237, 235, 0.32),
  0 10px 15px -3px rgba(210, 237, 235, 0.97), 0 1px 6px 0 #c9efed;
}
.s-header {
  display: flex;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #4fe9a6;
  padding: 20px;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}
.s-body {
  padding: 20px;
}
.s-lineup {
  display: flex;
  align-items: center;
  gap: 10px;
}
.s-spread {
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1000px) {
    width: 600px;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    width: 300px;
    gap: 7px;
  }
}
.s-full {
  width: 100%;
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.s-metamask-text {
  @media screen and (max-width: 1000px){
    display: none;
  }
}

.s-next-text {
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    font-size: 9px;
  }
}
</style>
