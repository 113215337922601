<template>
  <table>
    <thead>
      <tr>
        <th
          v-for="(column, index) of props.columns"
          :key="index"
          :first="index === 0"
        >
          {{ column.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) of props.items"
        :key="rowIndex"
      >
        <td
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :first="itemIndex === 0"
          :bold="column?.bold"
        >
          <slot
            :name="column"
            :item="row"
          >
            {{ getValue(row, column) }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="accordion">
    <div
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      class="accordion__item"
    >
      <div
        v-for="(column, index) in columns"
        :key="index"
        class="accordion__row-wrap"
      >
        <div class="accordion__row">
          <div>
            {{ column.title }}
          </div>

          <div>
            {{ getValue(item, column) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";

const props = defineProps({
  columns: {
    type: Array,
    default: () => []
  },
  items: {
    type: Array,
    default: () => []
  }
});

const getValue = (item, column) => {
  return _.get(item, column.name);
};
</script>

<style scoped lang="scss">
.accordion {
  display: none;
  flex-direction: column;

  gap: 15px;

  color: #1F3A43;

  @media (max-width: 800px) {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &:nth-child(2n) {
      .accordion__row-wrap {
        background: #E2EEF2;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #537C87;
  }

  &__row-wrap {
    padding: 15px;

    background: #F2FAFC;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

table {
  width: 100%;

  @media (max-width: 800px) {
    display: none;
  }
}
th {
  background: #DFFAF6;
  font-weight: 400;
  font-size: 16px;
  padding: 15px 20px;
  border-left: 5px solid white;
  text-align: start;

  &[first="true"] {
    border-left: none;
  }
}
tr {
  border-top: 5px solid white;
  background: #F2FAFC;

  &:nth-child(2n) {
    background: #E2EEF2;
  }
}
td {
  font-weight: 400;
  font-size: 16px;
  padding: 15px 20px;
  border-left: 5px solid white;
  &[first="true"] {
    border-left: none;
  }
  &[bold="true"] {
    text-align: end;
    font-weight: 600;
  }
}
</style>
