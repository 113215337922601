<template>
  <VueFinalModal :focus-trap="false">
    <div
      :stage="stage"
      class="modal"
    >
      <img
        class="decoration"
        src="/img/modal/decoration.svg"
      >

      <img
        class="close desktop"
        src="/img/modal/close.svg"
        alt=""
        @click="$emit('close')"
      >

      <img
        class="close mobile"
        src="/img/modal/close-mobile.svg"
        alt=""
        @click="$emit('close')"
      >

      <div class="header">
        <span class="title"> {{ $t("tokenBuy.buyAUT") }} </span>

        <div v-if="stage < 4">
          <span class="text"> {{ $t("tokenBuy.presale") }}: </span>
          <span class="content">
            <img src="/img/modal/a-logo.svg"> {{ `${$t("tokenBuy.aut")}` }}
            <img src="/img/modal/t-logo.svg">
            {{ `${price} ${$t("tokenBuy.usdt")}` }}</span>
        </div>

        <span
          v-if="stage < 4"
          :hide="stage === 1"
          class="cost"
        >
          <span>{{ $t("tokenBuy.forPayment") }}</span> <img src="/img/modal/t-logo.svg">
          {{ sum }}&nbsp;{{ $t("tokenBuy.usdt") }}
        </span>
      </div>

      <div
        class="body"
        :stage="stage"
      >
        <div v-if="stage === 1">
          <div class="buttons stage-two">
            <AuButton
              padding="18px 20px"
              font-size="20px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="typeOfPay == 'wallet'"
              class="desktop"
              @click="typeOfPay = 'wallet'"
            >
              <img
                v-if="typeOfPay === 'wallet'"
                src="/img/modal/metamask.svg"
              >
              <img
                v-else
                src="/img/modal/metamask_dim.svg"
              >
              {{ $t("tokenBuy.wallet") }}
            </AuButton>

            <AuButton
              padding="18px 20px"
              font-size="20px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="typeOfPay == 'exchange'"
              class="desktop"
              @click="typeOfPay = 'exchange'"
            >
              <img
                v-if="typeOfPay === 'exchange'"
                class="cards-icon"
                src="img/modal/cards.png"
              >
              <img
                v-else
                class="cards-icon"
                src="img/modal/cards.png"
              >
              {{ $t("tokenBuy.exchange") }}
            </AuButton>

            <AuButton
              padding="10px 10px"
              font-size="13px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter"
              :active="typeOfPay == 'wallet'"
              class="mobile"
              @click="typeOfPay = 'wallet'"
            >
              <img
                v-if="typeOfPay === 'wallet'"
                class="mobile-icon"
                src="/img/modal/metamask.svg"
              >
              <img
                v-else
                class="mobile-icon"
                src="/img/modal/metamask_dim.svg"
              >
              {{ $t("tokenBuy.wallet") }}
            </AuButton>
            <AuButton
              padding="10px 10px"
              font-size="13px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter"
              :active="typeOfPay == 'exchange'"
              class="mobile"
              @click="typeOfPay = 'exchange'"
            >
              <img
                v-if="typeOfPay === 'exchange'"
                class="mobile-icon-cards"
                src="img/modal/cards.png"
              >
              <img
                v-else
                class="mobile-icon-cards"
                src="img/modal/cards.png"
              >
              {{ $t("tokenBuy.exchange") }}
            </AuButton>
          </div>

          <div>
            <div
              class="country-link"
              :wert="typeOfPay === 'exchange'"
              @click="goToSupportCountries"
            >
              {{ typeOfPay === "exchange" ? $t("tokenBuy.supportedCountry") : "" }}
            </div>
            <div class="inputs">
              <Popper
                :content="emailCorrect"
                arrow
                placement="bottom"
                :show="emailCorrect.length > 0"
              >
                <AuInput
                  v-model="email"
                  :placeholder="$t('tokenBuy.emailPlaceholder')"
                  color="#1F3A43"
                  class="email"
                />
              </Popper>
            </div>
          </div>
        </div>
        <div v-if="stage === 2">
          <div
            v-if="typeOfPay==='wallet'"
            class="buttons"
          >
            <AuButton
              padding="18px 62px"
              font-size="26px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'bsc'"
              class="desktop"
              @click="blockChain = 'bsc'"
            >
              {{ $t("tokenBuy.bsc") }}
            </AuButton>

            <AuButton
              padding="18px 62px"
              font-size="26px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'avax'"
              class="desktop"
              @click="blockChain = 'avax'"
            >
              {{ $t("tokenBuy.avax") }}
            </AuButton>

            <AuButton
              padding="18px 62px"
              font-size="26px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'ethereum'"
              class="desktop"
              @click="blockChain = 'ethereum'"
            >
              {{ $t("tokenBuy.ethereum") }}
            </AuButton>

            <AuButton
              padding="4px 20px"
              font-size="14px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'bsc'"
              class="mobile"
              @click="blockChain = 'bsc'"
            >
              {{ $t("tokenBuy.bsc") }}
            </AuButton>

            <AuButton
              padding="4px 20px"
              font-size="14px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'avax'"
              class="mobile"
              @click="blockChain = 'avax'"
            >
              {{ $t("tokenBuy.avax") }}
            </AuButton>

            <AuButton
              padding="4px 20px"
              font-size="14px"
              background-color="#00000000"
              border-color="#A6BCC2"
              text-color="#A6BCC2"
              font-family="Inter Bold"
              :active="blockChain == 'ethereum'"
              class="mobile"
              @click="blockChain = 'ethereum'"
            >
              {{ $t("tokenBuy.ethereum") }}
            </AuButton>
          </div>

          <div
            v-else
            class="cards-title"
          >
            {{ $t("tokenBuy.exchange") }}
          </div>

          <div class="pay-blocks">
            <div class="pay-block">
              <div class="pay-block__header">
                {{ $t("tokenBuy.pay") }}
              </div>

              <Popper
                :content="sumCorrect"
                arrow
                placement="bottom"
                :show="sumCorrect.length > 0"
              >
                <div class="pay-block__body">
                  <CurrencyInput v-model="sum" />

                  <span class="currency">
                    <img src="/img/modal/t-logo.svg"> USDT
                  </span>
                </div>
              </Popper>

              <!--div class="restrictions">
                <div>
                  <span class="sign">Min: </span>
                  <span class="value">20 USDT</span>
                </div>
                <div>
                  <span class="sign">Max: </span>
                  <span class="value">20000 USDT</span>
                </div>
              </div-->

            </div>

            <div class="pay-block">
              <div class="pay-block__header">
                {{ $t("tokenBuy.count") }}
              </div>

              <div class="pay-block__body">
                <span class="cost">
                  <img src="/img/modal/a-logo-small.svg"> {{ count }} AUT
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="stage === 3"
        >
          <Step3
            v-if="step3FormView==='formWithQrCode'"
            :buy-message="buyMessage"
            :address="address"
            :sum="sum"
            :show-error-balance="showErrorBalance"
            :metamask-loading="metamaskLoading"
            :metamask-btn-text="metamaskBtnText"
            @copy="copyAddress"
            @metamask="actionMetamask"
          />
          <div
            v-else
            class="corners-wrap"
          >
            <div class="corners">
              <img src="/img/modal/corner-top-left.svg">
              <img src="/img/modal/corner-bottom-left.svg">
            </div>

            <div class="content">
              <div class="title">
                {{ buyMessage }}
              </div>

              <div class="address">
              <span>
                {{ address }}
              </span>
                <div>
                  <SuccessMark
                    v-show="copied"
                    :show-animate="copied"
                  />
                  <img
                    v-if="!copied"
                    src="/img/modal/copy.svg"
                    @click="copyAddress"
                  >
                </div>
              </div>

              <div
                class="transfer"
                :error="showErrorBalance"
              >
                {{ showErrorBalance ? $t("tokenBuy.transferError") + sum + " USDT" :
                $t("tokenBuy.transfer1") }}
              </div>

              <div
                class="metamask"
                @click="actionMetamask"
              >
                <img src="/img/modal/metamask.svg">
                <SpinnerLoader
                  v-if="metamaskLoading"
                  color="#5AC396"
                  size="34px"
                />
                <span>{{ metamaskBtnText }}</span>
              </div>
              <div class="explanation">
                {{ $t("tokenBuy.transfer2") }}
              </div>
            </div>

            <div class="corners">
              <img src="/img/modal/corner-top-right.svg">
              <img src="/img/modal/corner-bottom-right.svg">
            </div>
          </div>
        </div>

        <div
          v-if="stage === 4"
          class="thank-wrap"
        >
          <div>
            <span class="head">
              {{ $t("tokenBuy.thanksLabel") }}
            </span>

            <div class="divider" />

            <div class="tokens">
              <span>
                {{ $t("tokenBuy.tokenType") }}:
                <p>AUT</p>
              </span>
              <span>
                {{ $t("tokenBuy.quantity") }}:
                <p>{{ count }}</p>
              </span>
              <span>
                {{ $t("tokenBuy.totalValue") }}:
                <p>{{ sum }} USDT</p>
              </span>
            </div>

            <div class="body-text">
              <div>
                {{ $t("tokenBuy.textSecondParagraph") }}
              </div>

              <p>{{ $t("tokenBuy.textThirdParagraph") }}</p>
            </div>
          </div>

          <div class="socials">
            <AuIcon link="https://www.linkedin.com/company/autcapital">
              <img
                src="/img/modal/linkedin.svg"
                alt="linkedin"
              >
            </AuIcon>
            <AuIcon link="https://discord.com/invite/aZVj8q2hmT">
              <img
                src="/img/modal/discord.svg"
                alt="discord"
              >
            </AuIcon>
            <AuIcon link="https://t.me/AUT_Community_EN">
              <img
                src="/img/modal/telegram.svg"
                alt="viber"
              >
            </AuIcon>
            <AuIcon link="https://twitter.com/AutCapital">
              <img
                src="/img/modal/x.svg"
                alt="x"
              >
            </AuIcon>
          </div>
        </div>

        <AuButton
          v-if="stage < 4"
          :is-progress="loading"
          shadow
          class="send-button desktop"
          padding="20px 62px"
          font-size="26px"
          hovered-bg-color="#81deb6"
          :key="stage"
          @click="goToNextStage"
        >
          {{ stage === 3 ? $t("tokenBuy.payButton") : $t("tokenBuy.next") }}
        </AuButton>

        <AuButton
          v-if="stage < 4"
          :is-progress="loading"
          shadow
          class="send-button mobile"
          padding="15px 30px"
          font-size="22px"
          hovered-bg-color="#81deb6"
          :key="-stage"
          @click="goToNextStage"
        >
          {{ stage === 3 ? $t("tokenBuy.payButton") : $t("tokenBuy.next") }}
        </AuButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import CurrencyInput from "@/components/CurrencyInput.vue";
import SpinnerLoader from "@/loaders/progress-spinner";
import SuccessMark from "@/components/SuccessMark.vue";

import axios from "axios";
import BigNumber from "bignumber.js";
import Step3 from "@/components/formBuy/Step3";
import WertWidget from "@wert-io/widget-initializer";
import { computed, inject, onMounted, ref, watch } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";
import { useWeb3Modal, useWeb3ModalProvider } from "@web3modal/ethers5/vue";
import { VueFinalModal } from "vue-final-modal";
import { Web3 } from "web3";
const { t } = useI18n({});
const toast = createToaster({
  position: "top",
  duration: 4000,
  dismissible: true
});

const props = defineProps({
  initEmail: {
    type: String,
    default: ""
  },
  initAddress: {
    type: String,
    default: ""
  }
});

const step3FormView = ref(inject("step3FormView"));
const price = ref(process.env.VUE_APP_PRICE);
const count = computed(() => +(sum.value / price.value).toFixed(8));
const blockChain = ref("bsc");
const sum = ref(80);
const sumCorrect = computed(() => {
  return (sum.value >= 0 && sum.value <= 20000) ? "" : t("tokenBuy.zeroError");
});

const email = ref(props.initEmail);
let typeOfPay = ref("wallet");
const address = ref(null);
const balance = ref(BigNumber(0));
const showErrorBalance = ref(false);
const emailCorrect = computed(() => {
  return !progress.value || /@/.test(email.value) ? "" : t("tokenBuy.addressError");
});

const buyMessage = computed(() => {
  return t("tokenBuy.exchangeAddress1") +
    count.value + " AUT " +
    t("tokenBuy.exchangeAddress2") +
    sum.value + " USDT " +
    t("tokenBuy.exchangeAddress3") + blockChain.value + " " +
    t("tokenBuy.exchangeAddress4");
});
const metamaskBtnText = computed(() => {
  let ret = t("tokenBuy.cryptoWalleTransfer");
  if (isReadyMetamask.value) {
    ret = t("tokenBuy.transferButton");
    ret = ret + (balance.value.isZero() || !showErrorBalance.value ? "" : " " + balance.value.toFixed(2) + "$");
  }
  return ret;
});

const stage = ref(1);
const progress = ref(false);
const loading = ref(false);
const metamaskLoading = ref(false);
try {
  window.carrotquest.track("$order_started");
}
catch (e) {
  console.error("carrot error", e);
}
const goToNextStage = async () => {
  progress.value = true;
  let resp = null;
  switch (stage.value) {
  case 1:
    if (emailCorrect.value.length > 0) {
      return;
    }
    try {
      window.carrotquest.identify([
        { op: "update_or_create", key: "$email", value: email.value }
      ]);
    }
    catch (e) {
      console.error("carrot error", e);
    }
    break;
  case 2:
    if (sumCorrect.value.length > 0) {
      return;
    }
    loading.value = true;
    address.value = await createPay(true);
    if (typeOfPay.value === "exchange") {
      resp = await createPay(false);
      openWertForm(resp);
      return;
    }
    loading.value = false;
    break;
  }
  progress.value = false;
  stage.value++;
};

let provider = {};
const isReadyMetamask = ref(false);
let account = [];
let metamaskStage = 1;
const actionMetamask = async () => {
  metamaskLoading.value = true;
  switch (metamaskStage) {
  case 1:
    {
      const { open } = useWeb3Modal();
      await open();
      const { walletProvider } = useWeb3ModalProvider();
      if (!walletProvider.value) {
        watch(walletProvider, async () => {
          provider = walletProvider.value;
          await initMetamask();
        });
      }
      else {
        provider = walletProvider.value;
        await initMetamask();
      }
    }
    metamaskStage++;
    break;
  case 2:
    await getBalance();
    if (balance.value.gte(sum.value)) {
      await send(sum.value);
    }
    else {
      showErrorBalance.value = true;
      metamaskStage++;
    }
    break;
  case 3:
    if (!balance.value.isZero()) {
      await send(balance.value.toString(10));
    }
    break;
  }
  metamaskLoading.value = false;
};

const initMetamask = async () => {
  if (!provider) {
    toast.error(t("tokenBuy.metamaskNotFound"));
    return;
  }

  try {
    account = await provider.request({ method: "eth_requestAccounts" });
  }
  catch (e) {
    toast.error(t("tokenBuy.metamaskFailConnect"));
    return;
  }

  account = account.at(0) ?? 0;
  provider.on("accountsChanged", () => account = account.at(0) ?? 0);

  const chainList = {
    bsc: {
      chainId: "0x38",
      chainName: "BNB Smart Chain Mainnet",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com/"],
      nativeCurrency: {
        symbol: "BNB",
        decimals: 18
      }
    },
    avax: {
      chainId: "0xa86a",
      chainName: "Avalanche C-Chain",
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://snowtrace.io"],
      nativeCurrency: {
        symbol: "AVAX",
        decimals: 18
      }
    },
    ethereum: {
      chainId: "0x1",
      chainName: "Ethereum Mainnet",
      rpcUrls: ["https://api.mycryptoapi.com/eth", "https://cloudflare-eth.com"],
      blockExplorerUrls: ["https://etherscan.io"],
      nativeCurrency: {
        symbol: "ETH",
        decimals: 18
      }
    }
  };
  const currentId = await provider.request({ method: "eth_chainId" });
  if (currentId === chainList[blockChain.value].chainId) {
    isReadyMetamask.value = true;
    return;
  }

  let needAdded = false;
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainList[blockChain.value].chainId }]
    });
  }
  catch (e) {
    if (e.code === 4902) {
      needAdded = true;
    }
    else {
      toast.error(t("tokenBuy.switchError"));
      return;
    }
  }

  if (needAdded) {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [chainList[blockChain.value]] });
    }
    catch (e) {
      toast.error(t("tokenBuy.addError"));
      return;
    }
  }
  isReadyMetamask.value = true;
};

const contractList = {
  ethereum: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  avax: "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
  bsc: "0x55d398326f99059fF775485246999027B3197955"
};
const send = async sendSum => {
  let abi = [
    {
      constant: false,
      inputs: [
        {
          internalType: "address",
          name: "recipient",
          type: "address"
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256"
        }
      ],
      name: "transfer",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool"
        }
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function"
    }
  ];

  const web3 = new Web3(provider);
  // console.log("contract: " + contractList[blockChain.value]);

  const contract = new web3.eth.Contract(abi, contractList[blockChain.value], { from: account });
  let decimals = blockChain.value === "bsc" ? 18 : 6;
  const amount = BigNumber(sendSum).multipliedBy(BigNumber(10).pow(decimals));
  // console.log(`amount: ${amount.toString(10)}; address: ${address.value}`);

  const data = contract.methods.transfer(address.value.toString(10), amount.toString(10)).encodeABI();
  // console.log(data);

  try {
    await provider.request({
      method: "eth_sendTransaction",
      params: [{
        from: account,
        to: contractList[blockChain.value],
        value: 0,
        data: data
        // gasLimit: "0x9c40",
        // maxPriorityFeePerGas: "0x0",
        // maxFeePerGas: "0xb2d05e00"
      }]
    });
  }
  catch (e) {
    toast.error(t("tokenBuy.sendError"));
  }
};

const getBalance = async () => {
  const abi = [{
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256"
      }
    ],
    payable: false,
    type: "function"
  }];
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abi, contractList[blockChain.value]);
  let data = await contract.methods.balanceOf(account).call();
  balance.value = BigNumber(data).dividedBy(BigNumber(10).pow(blockChain.value === "bsc" ? 18 : 6));
};

const getRefId = () => {
  let refId = document.cookie.match(/ref_id=[a-zA-Z0-9]{1,}/);
  if (refId) {
    refId = refId[0].slice(7);
  }
  return refId;
};

const copied = ref(false);

const copyAddress = () => {
  navigator.clipboard.writeText(address.value).then(() => {
    copied.value = true;
    setTimeout(function() {
      copied.value = false;
    }, 1700);
  });
};

const groupAB = inject("groupAB");
const createPay = async isPayFromExchange => {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/ld+json"
  };
  const params = {
    autValue: String(count.value),
    payValue: String(sum.value),
    email: email.value,
    blockChain: blockChain.value,
    addressFrom: props.initAddress,
    isPayFromExchange: isPayFromExchange,
    isNotifiedOfPayd: true,
    isPaymentConfirmed: true,
    siteVersion: groupAB === 0 ? "header1" : "header2"
  };
  const carrot_id = document.cookie.match(/carrotquest_uid=[a-zA-Z0-9]{1,}/);
  if (carrot_id) {
    params["carrot_id"] = carrot_id[0].slice("carrotquest_uid=".length);
  }

  const refId = getRefId();
  if (refId) {
    params.userRefId = refId;
  }
  let ret = null;
  try {
    const resp = await axios.post("/api/exchange_pays/create", params, { headers });
    ret = isPayFromExchange ? resp.data.address : resp.data;
    window.carrotquest.track("$order_completed", {
      "$order_id": resp.data.address,
      "$order_id_human": resp.data.address,
      "$order_amount": String(sum.value)
    });
    window.carrotquest.identify([
      { op: "add", key: "$orders_count", value: 1 },
      { op: "add", key: "$revenue", value: String(sum.value) },
      { op: "update_or_create", key: "$last_payment", value: String(sum.value) }
    ]);
  }
  catch (e) {
    ret = t("tokenBuy.exchangeError");
  }
  return ret;
};

const emit = defineEmits(["close"]);
const openWertForm = params => {
  params.currency_amount = sum.value;
  params.network = "polygon";
  params.commodity = "USDT";
  params.address = address.value;
  const wertWidget = new WertWidget(params);
  wertWidget.mount();
  setTimeout(() => emit("close"), 1500);
};

const goToSupportCountries = () => {
  if (typeOfPay.value === "exchange") {
    window.open("https://support.wert.io/en/articles/5194509-supported-countries", "_blank");
  }
};

onMounted(async () => {
  const resp = await axios.get("/api/exchange_pays/get_currency");
  price.value = resp.data?.currency ?? 0;
});
</script>

<style lang="scss">
.vfm__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .inputs {
    .input-icon {
      top: 36px;
    }
    .input {
      height: 60px !important;
      font-size: 14px !important;
      padding: 16px 10px 0px 45px !important;
    }
    .email {
      .input {
        padding: 8px 10px !important;
        font-size: 14px !important;
        height: 33px !important;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.modal {
  position: relative;

  width: 1120px;
  height: 600px;
  background: #daedf3;

  border-radius: 30px;
  background: linear-gradient(256deg, #daedf3 -2.62%, #fefeff 77.25%, #fff 77.25%);

  padding: 40px;
  &[stage="1"] {
    height: 400px;
  }
  @media screen and (max-width: 1200px) {
    margin: 0 15px;
    padding: 15px;
    height: 572px;
    width: 400px;

    @media screen and (max-width: 410px) {
      width: calc(100% - 40px);
    }

    border-radius: 15px;
    &[stage="2"] {
      height: 600px;
    }
    &[stage="3"] {
      height: 475px;
    }
    &[stage="4"] {
      height: 467px;
    }

    @media screen and (max-width: 800px) {
      padding: 20px;
    }
  }

  .thank-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
      color: #1f3a43;
      font-family: Inter SemiBold;
      font-size: 38px;
      @media screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }

    .divider {
      width: 140px;
      border-bottom: 4px #4fe9a6 solid;

      margin-top: 35px;
      margin-bottom: 20px;

      @media screen and (max-width: 1200px) {
        width: 40px;
      }
    }

    .tokens {
      color: #000;
      font-size: 22px;
      margin-bottom: 27px;
      display: flex;
      gap: 20px;

      @media screen and (max-width: 1200px) {
        font-size: 14px;
        flex-direction: column;
        gap: 8px;
      }

      span {
        display: flex;
        gap: 5px;
      }

      p {
        font-family: Inter Bold;
      }
    }

    .body-text {
      display: flex;
      flex-direction: column;

      gap: 20px;

      color: #1f3a43;
      font-size: 22px;

      @media screen and (max-width: 1200px) {
        font-size: 13px;
      }

      a {
        color: #2aba7c;
        text-decoration-line: underline;
      }

      p {
        color: #1f3a43;
        font-family: Inter Bold;
      }
    }
  }

  .socials {
    display: flex;
    gap: 25px;
    @media screen and (max-width: 1200px) {
      gap: 16px;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .attention {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    color: #fff;

    background: #1f3a43;
    font-size: 20px;
    padding: 24px 10px;

    margin: 40px 0px;

    @media screen and (max-width: 1200px) {
      margin: 70px 0px;
      padding: 10px;
      font-size: 12px;
      gap: 0;
      line-height: 1.6;
      align-items: start;
    }
  }

  .pay-blocks {
    display: flex;
    gap: 30px;
    margin-top: 50px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  .pay-block {
    display: flex;
    padding: 35px 0px 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    width: 100%;

    border-radius: 15px;
    background: #f7fbfd;
    box-shadow: 0px 18px 33px 0px rgba(210, 237, 235, 0.32),
    0px 10px 15px -3px rgba(210, 237, 235, 0.97), 0px 1px 6px 0px #c9efed;

    @media screen and (max-width: 1200px) {
      padding: 10px 0px 20px 0px;
    }

    &__header {
      display: flex;
      justify-content: center;

      padding-bottom: 20px;
      border-bottom: 1px solid #4fe9a6;

      width: 100%;

      color: #1f3a43;
      font-family: Inter SemiBold;
      font-size: 24px;

      @media screen and (max-width: 1200px) {
        padding-bottom: 10px;
        font-size: 20px;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      gap: 8px;

      height: 100%;

      .currency {
        display: flex;
        align-items: center;
        gap: 5px;

        color: #1f3a43;
        font-size: 20px;

        @media screen and (max-width: 1200px) {
          font-size: 18px;
        }
      }
    }
  }

  .decoration {
    height: 100%;
    position: absolute;
    bottom: 1px;
    right: 2px;
    border-bottom-right-radius: 20px;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: -30px;
    top: -20px;

    &.mobile {
      display: none;
    }

    @media screen and (max-width: 1500px) {
      top: 30px;
      right: 30px;

      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        z-index: 1003;
      }
    }


    @media screen and (max-width: 1200px) {
      top: 15px;
      right: 15px;
    }
  }

  .cost {
    color: #51af95;
    font-family: Inter Bold;
    font-size: 26px;
    span {
      display: none;
    }

    @media screen and (max-width: 1200px) {
      font-size: 18px;
      display: flex;
      gap: 5px;
      align-items: center;

      span {
        color: #1f3a43;
        font-size: 18px;
        display: block;
      }
    }

    &[hide="true"] {
      opacity: 0;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    z-index: 1002;
    position: relative;

    padding: 0px 0 30px 0;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 10px 20px 10px 20px;
      gap: 10px;
    }

    .title {
      color: #1f3a43;
      font-size: 48px;
      font-family: Inter SemiBold;

      @media screen and (max-width: 1200px) {
        font-size: 26px;
      }
    }

    .content {
      color: #1f3a43;
      font-size: 26px;

      @media screen and (max-width: 1200px) {
        font-size: 14px;
      }
    }

    .text {
      color: #1f3a43;
      font-size: 26px;
      font-family: Inter SemiBold;

      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }

      @media screen and (max-width: 450px) {
        font-size: 0.5em;
      }
    }
  }

  .body {
    height: 70%;
    padding: 45px;

    border-radius: 20px;
    border: 2px solid #2aba7c;

    z-index: 1002;
    position: relative;
    &[stage="1"] {
      height: 55%;
    }
    @media screen and (max-width: 1200px) {
      padding: 16px 20px 40px 20px;
      &[stage="2"] {
        height: 65%;
        padding: 16px 20px 30px 20px;
      }
      &[stage="3"] {
        height: 55%;
        padding: 16px 20px 30px 20px;
      }
      &[stage="4"] {
        height: 75%;
      }
    }

    .send-button {
      &.mobile {
        display: none;
      }
      @media screen and (max-width: 1200px) {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }

    .group {
      display: flex;
      flex-direction: column;

      position: relative;

      .text {
        position: absolute;

        color: #1f3a43;
        font-size: 15px;

        left: 30px;
        top: 5px;

        @media screen and (max-width: 1200px) {
          font-size: 11.7px;
          left: 15px;
          top: 8px;
        }
      }
    }

    .inputs {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 25px;
        margin-top: 55px;
      }

      .inline-block {
        width: 75%;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      gap: 20px;

      .mobile {
        display: none;
      }

      @media screen and (max-width: 1200px) {
        height: 40px;
        gap: 8px;
        .desktop {
          display: none;
        }

        .mobile {
          display: block;
        }

        &.stage-two {
          height: 52px;
          flex-direction: column;
          margin-top: 12px;
        }
      }

      .btn {
        width: 100%;
        justify-content: center;

        .btn__text {
          font-family: Inter Bold;
        }
      }
    }

    .corners-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-direction: row;

      @media screen and (max-width: 1200px) {
        justify-content: center;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          color: #1f3a43;
          font-family: Inter SemiBold;
          font-size: 38px;
          text-align: center;

          @media screen and (max-width: 1200px) {
            font-size: 20px;
          }

          @media screen and (max-width: 400px) {
            font-size: 16px;
          }
        }

        .address {
          display: inline-flex;
          padding: 21px 32px;
          justify-content: center;
          align-items: center;
          gap: 20px;

          border-radius: 10px;
          background: #c1f9e0;

          margin: 20px 0px;

          color: #096f57;
          font-family: Inter Bold;
          font-size: 26px;

          span {
            @media screen and (max-width: 1200px) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          img {
            width: 48px;
            height: 48px;
            &:hover {
              cursor: pointer;
            }
          }

          @media screen and (max-width: 1200px) {
            padding: 8px;
            font-size: 12px;
            gap: 5px;
            width: 230px;

            img {
              height: 20px;
              width: 20px;
            }
          }
        }

        .transfer {
          color: #000;
          font-size: 22px;
          text-align: center;
          &[error="true"] {
            color: red;
          }

          @media screen and (max-width: 1200px) {
            font-size: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
          }
          @media screen and (max-width: 400px) {
            font-size: 8px;
          }


          > span {
            font-family: Inter Bold;
          }
        }

        .metamask {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;

          padding: 18px 39px;
          margin-top: 20px;

          border-radius: 10px;
          border: 1.5px dashed #2aba7c;

          color: #2aba7c;
          font-family: Inter Medium;
          font-size: 24px;
          cursor: pointer;

          @media screen and (max-width: 1200px) {
            padding: 12px 20px;
            font-size: 15px;
            img {
              height: 26px;
              width: 27px;
            }
          }
        }
      }
    }

    .corners {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    > div {
      height: 100%;
    }
  }

  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.first {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;

  .box {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.btn {
  margin-left: auto;
  margin-right: auto;
}
.mobile-icon {
  width: 20px;
  height: 20px;
}
.metamask-pos {
  display: flex;
  justify-content: end;
  align-items: start;
  margin-top: 10px;
}
.metamask-ico{
  width: 25px;
  height: 25px;
}
.this-ico {
  height: 35px;
}
.metamask-button {
  width: 408px;
  height: 40px;
  background-color: #FFC289;
  border-radius: 10px;
  border: none;
}
.restrictions {
  width: 50%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    width: 85%;
  }
  .sign {
    font-size: 15px;
    font-weight: 400;
  }
  .value {
    font-size: 15px;
    font-weight: 600;
  }
}
.cards-icon {
  width: 100px;
  height: 40px;
}
.mobile-icon-cards {
  width: 50px;
  height: 20px;
}
.cards-title {
  font-size: 40px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
}
.explanation {
  font-size: 16px;
  margin-top: 10px;
  @media screen and (max-width: 1200px) {
    font-size: 10px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 9px;
    margin-top: 5px;
  }
}
.country-link {
  display: flex;
  justify-content: center;
  height: 20px;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: underline;
  @media screen and (max-width: 1000px) {
    margin-top: 30px;
  }
  &[wert="true"] {
    cursor: pointer;
  }
}
</style>
