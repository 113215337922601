<template>
  <div class="verify-back">
    <div v-if="isCorrect===0">
      <div class="verify-contain">
        <span class="verify-title">{{ $t("emailVerify.check") }}...</span>
      </div>
    </div>
    <div
      v-else
      class="verify-contain"
    >
      <div
        class="verify-title"
        :isCorrect="isCorrect"
      >
        {{ isCorrect === 1 ? $t("emailVerify.success") : $t("emailVerify.error") }}
      </div>
      <AuButton @click="goToPage">
        {{ isCorrect === 1 ? $t("emailVerify.login") : $t("emailVerify.register") }}
      </AuButton>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import Trans from "@/localization/translation";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const isCorrect = ref(0);
const props = defineProps({
  hash: {
    type: String,
    default: ""
  }
});
onMounted(async () => {
  await checkEmail();
});
const headers = {
  accept: "*/*",
  "Content-Type": "application/json"
};
const checkEmail = async () => {
  const params = {
    hash: props.hash
  };
  try {
    await axios.post("/api/users/email_verify",
      params,
      { headers: headers });
    isCorrect.value = 1;
  }
  catch (e) {
    if (e.response.status === 400) {
      isCorrect.value = -1;
    }
  }
};

const router = useRouter();
const goToPage = () => {
  router.push({ name: isCorrect.value === 1 ? "Login" : "Register",
    params: { locale: Trans.currentLocale }
  });
};
</script>

<style lang="scss">
.verify-back {
  background: #1F3A43;
  height: 100vh;
}
.verify-contain {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}
.verify-title {
  color: white;
  font-weight: bold;
  font-size: 58px;
  margin-top: 150px;
  &[isCorrect="-1"] {
    color: red;
  }
 }
</style>
