<template>
  <section class="tokens-section container">
    <div class="content-wrapper">
      <h1>{{ $t("tokensSection.header") }}</h1>

      <div class="links">
        <a
          target="”_blank”"
          href="https://bscscan.com/token/0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b"
        ><img src="/img/tokens/link.svg">{{ $t("tokensSection.contract-link") }}</a>
        <a
          target="”_blank”"
          href="https://github.com/Autentic-Capital/autentic-token"
        ><img src="/img/tokens/link.svg">{{ $t("tokensSection.git-link") }}</a>
      </div>

      <div>
        <TokensTable />
      </div>
    </div>

    <div>
      <img
        class="donut"
        src="/img/tokens/donut.png"
      >

      <div class="legend">
        <div
          v-for="(element, index) in elements"
          :key="index"
          class="legend-item"
        >
          <img :src="`/img/tokens/${element.img}.svg`">&nbsp;{{ element.distribution }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";

import TokensTable from "@/components/TokensTable.vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

const elements = computed(() => [{
  distribution: t("tokensSection.elements.team"),
  img: "team"
},
{
  distribution: t("tokensSection.elements.ecosystem"),
  img: "ecosystem"
},
{
  distribution: t("tokensSection.elements.advisers"),
  img: "advisers"
},
{
  distribution: t("tokensSection.elements.stacking"),
  img: "stacking"
},
{
  distribution: t("tokensSection.elements.marketing"),
  img: "marketing"
},
{
  distribution: t("tokensSection.elements.direct"),
  img: "direct"
}]);
</script>

<style lang="scss" scoped>
.content-wrapper {
  color: #1f3a43;
  width: 100%;

  h1 {
    max-width: 1206px;
    font-size: 48px;
    margin-bottom: 20px;
    font-family: Inter SemiBold;
  }

  a {
    color: #2ABA7C;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 143.523%;
    text-decoration-line: underline;

    @media screen and (max-width: 1500px) {
      font-size: 16px;
    }

    &:last-child {
      margin-left: 30px;
    }
  }
}

.donut {
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
}

.legend {
  display: flex;
  gap: 25px;
  margin-left: 90px;
  flex-wrap: wrap;

  margin-top: -30px;

  &-item {
    display: flex;
    align-items: center;

    gap: 8px;
    font-size: 18px;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }
  }
}

.links {
  display: flex;
  gap: 30px;

  margin-bottom: 30px;
}

.tokens {
  &-section {
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: start;

    padding-top: 60px;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &-list {
    display: flex;
    column-gap: 31px;
    row-gap: 81px;
    flex-wrap: wrap;
  }
}
</style>
