<template>
  <section class="container faq-section">
    <h1>
      FAQ
    </h1>

    <div class="faqs">
      <div class="faq">
        <h2>{{ $t("faq.1-title") }}</h2>
        <span>
          {{ $t("faq.1-text") }}
        </span>
      </div>

      <div class="faq">
        <h2>{{ $t("faq.2-title") }}</h2>
        <span>
          {{ $t("faq.2-text") }}
        </span>
      </div>

      <div class="faq">
        <h2>{{ $t("faq.3-title") }}</h2>
        <span>
          {{ $t("faq.3-text") }}
        </span>
      </div>

      <div class="faq">
        <h2>{{ $t("faq.4-title") }}</h2>
        <span>
          {{ $t("faq.4-text") }}
        </span>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
  z-index: 2;
  background: white;

  color: #1F3A43;
}

.faq-section {
  h1 {
    font-size: 68px;

    margin-bottom: 40px;
  }

  h2 {
    font-size: 30px;
    font-family: Inter SemiBold;

    margin-bottom: 10px;
  }

  span {
    font-size: 22px;

    line-height: 32px;
  }

  .faqs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>