<template>
  <div class="table-wrap">
    <table>
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="element in elements"
          :key="element.distribution"
        >
          <td>
            {{ element.distribution }}
          </td>
          <td>
            {{ element.share }}
          </td>
          <td>
            {{ element.volume }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

const columns = computed(() => [t("tokensSection.table.header.distribution"),
  t("tokensSection.table.header.share"),
  t("tokensSection.table.header.volume")]);

const elements = computed(() => [{
  distribution: t("tokensSection.elements.team"),
  share: "25%",
  volume: "37 500 000"
},
{
  distribution: t("tokensSection.elements.ecosystem"),
  share: "15%",
  volume: "22 500 000"
},
{
  distribution: t("tokensSection.elements.advisers"),
  share: "5%",
  volume: "7 500 000"
},
{
  distribution: t("tokensSection.elements.stacking"),
  share: "15%",
  volume: "22 500 000"
},
{
  distribution: t("tokensSection.elements.marketing"),
  share: "10%",
  volume: "15 000 000"
},
{
  distribution: t("tokensSection.elements.direct"),
  share: "30%",
  volume: "45 000 000"
},
{
  distribution: t("tokensSection.elements.total"),
  share: "100%",
  volume: "150 000 000"
}]);
</script>

<style scoped lang="scss">
.table-wrap {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 72px 52px -24px rgba(200, 225, 225, 0.32), 0px 4px 12px 0px rgba(198, 212, 219, 0.56), 0px 23px 17px 0px rgba(188, 219, 223, 0.58), 0px 8px 4px 0px rgba(186, 211, 229, 0.58);
}

table {
  border-collapse: inherit;
  border-spacing: 8px;
  line-height: 23px;
  width: 100%;

  tr {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:nth-child(2n) {
      td {
        background: #E7FFF4;
      }
    }

    td {
      padding: 18px 30px;

      border-radius: 3px;
      border: 1px solid #4FE9A6;
      background: #F5FFFB;

      font-size: 22px;
      font-family: Inter SemiBold;

      @media screen and (max-width: 1500px) {
        font-size: 16px;
      }

      &:last-child {
        &:first-child {
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    th {
      padding: 18px 30px;

      border-radius: 3px;
      background: #3DA680;

      color: #FFF;
      font-size: 18px;
      font-family: Inter Medium;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
}
</style>