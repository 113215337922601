<template>
  <div class="cc-layout">
    <span class="cc-digit">
      {{ day < 10 ? `0${day}` : day }}
    </span>
    <span class="cc-digit">
      :
    </span>
    <span class="cc-digit">
      {{ hour < 10 ? `0${hour}` : hour }}
    </span>
    <span class="cc-digit">
      :
    </span>
    <span class="cc-digit">
      {{ minute < 10 ? `0${minute}` : minute }}
    </span>

    <span class="cc-legend">
      Days
    </span>
    <span />
    <span class="cc-legend">
      Hours
    </span>
    <span />
    <span class="cc-legend">
      Min
    </span>
  </div>
</template>

<script setup>

import { onMounted, ref } from "vue";

const dot1 = process.env.VUE_APP_LISTING.indexOf(".");
const dot2 = process.env.VUE_APP_LISTING.lastIndexOf(".");
let tickDate = (new Date(Date.UTC(process.env.VUE_APP_LISTING.slice(dot2 + 1),
  process.env.VUE_APP_LISTING.slice(dot1 + 1, dot2) - 1,
  process.env.VUE_APP_LISTING.slice(0, dot1), 0, 0, 0)
) - new Date()) / 1000;

let day = ref(0);
let hour = ref(0);
let minute = ref(0);
const doTick = () => {
  if (tickDate < 0) {
    return;
  }
  let buf = tickDate;
  day.value = Math.floor(buf / (3600 * 24));
  buf -= day.value * (3600 * 24);

  hour.value = Math.floor(buf / 3600);
  buf -= hour.value * 3600;

  minute.value = Math.ceil(buf / 60);
  tickDate--;
};

onMounted(() => {
  doTick();
  setInterval(doTick, 1000);
});
</script>

<style scoped lang="scss">
.cc-layout {
  display: grid;
  grid-template-columns: 90px 30px 90px 30px 90px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 60px 30px 60px 30px 60px;
  }
}
.cc-digit {
  font-size: 80px;
  color: #058E6E;
  text-align: center;
  text-shadow: 0 10px 18px;

  @media screen and (max-width: 600px) {
    font-size: 50px;
  }
}
.cc-legend {
  font-size: 12px;
  text-align: center;
}
</style>
