import "../public/scss/main.scss";
import "vue-final-modal/style.css";

import App from "./App.vue";
import auth from "./auth";
import axios from "axios";
import components from "@/components/extended/components";
import i18n from "./localization/localization";
import Popper from "vue3-popper";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import { createApp } from "vue";
import { createHead } from "@unhead/vue";
import { createVfm } from "vue-final-modal";

const app = createApp(App);

app.config.globalProperties.$filters = {
  correctPassword(password) {
    return /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8}/.test(password);
  }
};
app.use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, axios)
  .use(auth)
  .use(createVfm())
  .use(vClickOutside)
  .use(VueApexCharts)
  .use(VueCookies)
  .use(createHead())
  .use(Toaster, {
    position: "top",
    duration: 4000,
    dismissible: true
  });
app.component("Popper", Popper).component("Apexchart", VueApexCharts);
app.axios.defaults.baseURL = process.env.VUE_APP_HOST_API;

for (const key in components) {
  const comp = components[key];
  app.component(key, comp);
}


app.mount("#app");
