<template>
  <div>
    <section class="advantages-section container">
      <div class="content-wrapper">
        <h1>
          {{ $t("advantagesSection.header") }}
        </h1>

        <div class="content-wrap">
          <div class="info">
            <AdvantagesTable />
          </div>
        </div>
      </div>
    </section>

    <section class="advantages-section-mini container">
      <div class="header">
        <h1>
          {{ $t("advantagesSection.header") }}
        </h1>

        <img
          class="corner"
          src="/img/advantages/corner.svg"
          alt="corner"
        >
      </div>

      <div
        v-for="advantage in advantages"
        :key="advantage.name"
        class="advantage-mini"
      >
        <img :src="`/img/advantages/${advantage.img}.png`">

        <span>
          {{ advantage.name }}
        </span>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";

import AdvantagesTable from "@/components/AdvantagesTable.vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

const advantages = computed(() => [
  {
    name: t("advantagesSection.advantages.risk.name"),
    text: t("advantagesSection.advantages.risk.text"),
    img: "risk"
  },
  {
    name: t("advantagesSection.advantages.quality.name"),
    text: t("advantagesSection.advantages.quality.text"),
    img: "quality"
  },
  {
    name: t("advantagesSection.advantages.discounts.name"),
    text: t("advantagesSection.advantages.discounts.text"),
    img: "discounts"
  }
]);
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  z-index: 999;

  h1 {
    color: #1f3a43;
    font-size: 68px;
    font-family: Inter SemiBold;
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
  }
}

.info {
  width: 100%;

  background: #FFFFFF;
  padding: 70px 130px;
}

.content-wrap {
  display: flex;
}

.advantage-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-right: 40px;
  border-right: #d2edebdb solid 1px;

  @media screen and (max-width: 1500px) {
    padding-right: 20px;
    gap: 40px;
  }
}

.advantage {
  width: 450px;

  @media screen and (max-width: 1500px) {
    width: 350px;
  }

  h2 {
    color: #1f3a43;
    font-family: Inter SemiBold;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 1500px) {
      font-size: 20px;
    }
  }

  p {
    color: #1f3a43;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 152.941% */

    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }
}

.advantages-section {
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;

  @media screen and (max-width: 1200px) {
    display: none;
  }

  &-mini {
    display: none;
    margin-top: 50px;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      margin-bottom: 10px;

      h1 {
        font-size: 26px;
        font-family: Inter SemiBold;
      }
    }

    .advantage-mini {
      display: flex;
      align-items: center;

      img {
        height: 114px;
      }

      span {
        font-size: 17;
        font-family: Inter SemiBold;

        margin-top: -15px;
      }
    }

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
}

img {
  &[alt="polygon"] {
    position: absolute;
    top: 0;
    right: 0;
    width: 35vw;
  }
}
</style>
