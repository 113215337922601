<template>
  <div>
    <Top
      :address="address"
      :total="total"
    />
    <div class="content">
      <div class="left-part">
        <div class="navigation">
          <LeftMenu
            :address="address"
            :email="userName"
          />
          <TokenInfo />
        </div>
        <img
          v-if="$route.name==='dashboard'"
          class="man"
          src="/img/cabinet/man.png"
          alt="man"
        >
      </div>
      <div class="user-view">
        <RouterView />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BigNumber from "bignumber.js";
import Footer from "@/components/cabinet/Footer";
import LeftMenu from "@/components/cabinet/LeftMenu";
import TokenInfo from "@/components/cabinet/TokenInfo";
import Top from "@/components/cabinet/Top";
import { computed, onMounted, ref } from "vue";

const userName = computed(() => {
  let ret = "";
  try {
    ret = JSON.parse(auth.remember());
  }
  catch (e) {
    return "";
  }
  return ret?.username ? ret.username : "";
});

const total = ref("-");
const address = ref("-");

const getData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    total.value = BigNumber(resp.data.mainBalance).plus(resp.data.referralBalance)
      .plus(resp.data.vestingBalance).plus(resp.data.autoStakingBalance ?? 0).toFixed(2);
    address.value = _.get(resp.data, "ethAddress", "");
  }
  catch (e) {
    console.log(e);
  }
};

onMounted(async () => {
  await getData();
});
</script>

<style lang="scss" scoped>
.content {
  background: linear-gradient(256deg, #daedf3 -2.62%, #fefeff 77.25%, #fff 77.25%);
  padding: 60px;
  display: flex;

  @media (max-width: 1600px) {
    padding: 40px 20px;
  }

  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.left-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-right: 60px;

  @media (max-width: 1600px) {
    display: none;
  }
}
.man {
  width: 35vw;
}
.navigation {
  width: 35vw;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.user-view {
  width: 100%;
}
</style>
