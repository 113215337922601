<template>
  <div>
    <div class="panel">
      <div class="panel-part left">
      <span class="title">
        {{ props.buyMessage }}
      </span>

        <div class="address">
        <span>
          {{ props.address }}
        </span>
          <div>
            <SuccessMark
              v-show="copied"
              :show-animate="copied"
            />
            <img
              v-if="!copied"
              src="/img/modal/copy.svg"
              alt=""
              @click="copyAdr"
            >
          </div>
        </div>

        <div class="steps">
          <span class="marker text">
            {{ $t("step3.step") + " 1" }}
          </span>
          <span class="text">
            {{ $t("step3.copy") }}
          </span>
          <span class="marker text">
            {{ $t("step3.step") + " 2" }}
          </span>
          <span class="qr-text">
            {{ $t("step3.insert") }}
            <span id="qr">
              {{ $t("step3.scan") }}
            </span>
          </span>
          <span class="marker text">
            {{ $t("step3.step") + " 3" }}
          </span>
          <span class="text">
            {{ $t("step3.send") + " " + sum + " USDT" }}
          </span>
        </div>
      </div>

      <div class="panel-part right">
      <span class="lifetime">
        {{ $t("step3.validity") }}
      </span>
        <div class="timer">
          <span>{{ hours }}</span>
          <span>:</span>
          <span>{{ minutes }}</span>
          <span class="legend">min</span>
          <span />
          <span class="legend">sec</span>
        </div>
        <img
          v-if="imageUrl"
          :src="imageUrl"
          class="qr"
          alt=""
        >
      </div>
    </div>
    <div class="panel-footer">
      <div class="text-place">
        <span
          v-if="props.showErrorBalance"
          class="error"
        >
          {{ $t("tokenBuy.transferError") + sum + " USDT" }}
        </span>
      </div>
      <div
        class="metamask"
        @click="$emit('metamask')"
      >
        <img
          src="/img/modal/metamask.svg"
          alt=""
        >
        <SpinnerLoader
          v-if="metamaskLoading"
          color="#5AC396"
          size="20px"
        />
        <span>{{ metamaskBtnText }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from "qrcode";
import SpinnerLoader from "@/loaders/progress-spinner";
import SuccessMark from "@/components/SuccessMark.vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  buyMessage: {
    type: String,
    default: ""
  },
  address: {
    type: String,
    default: ""
  },
  sum: {
    type: Number,
    default: 0
  },
  showErrorBalance: {
    type: Boolean,
    default: false
  },
  metamaskLoading: {
    type: Boolean,
    default: false
  },
  metamaskBtnText: {
    type: String,
    default: ""
  }
});
const emit = defineEmits(["copy"]);

const copied = ref(false);
const hours = ref("00");
const minutes = ref("00");
const imageUrl = ref(null);
let timeBuf = 3600;

const copyAdr = () => {
  emit("copy");
  copied.value = true;
  setTimeout(() => copied.value = false, 1700);
};

const tickTimer = () => {
  if (timeBuf > 0 && timeBuf < 5999) {
    timeBuf--;
    hours.value = Math.floor(timeBuf / 60).toString().padStart(2, "0");
    minutes.value = (timeBuf - (hours.value * 60)).toString().padStart(2, "0");
  }
};

onMounted(async () => {
  tickTimer();
  setInterval(tickTimer, 1000);
  imageUrl.value = await QRCode.toDataURL(props.address);
});
</script>

<style scoped lang="scss">
.panel {
  display: flex;
}
.panel-part {
  display: flex;
  flex-direction: column;
}
.left {
  width: 75%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
.right {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 5px;
  }
}
.address {
  display: inline-flex;
  padding: 21px 32px;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 10px;
  background: #c1f9e0;

  color: #096f57;
  font-size: 22px;

  span {
    @media screen and (max-width: 1200px) {
      white-space: nowrap;
      overflow: hidden;
    }
  }

  img {
    width: 48px;
    height: 48px;
    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 8px;
    font-size: 12px;
    gap: 5px;

    img {
      height: 20px;
      width: 20px;
    }
  }
};
.steps {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 18% auto;
  gap: 10px 12px;
  font-size: 17px;

  @media screen and (max-width: 1200px) {
    font-size: 12px;
    margin-top: 15px;
  }

  .marker {
    padding: 5px 10px;
    background: #c1f9e0;
    font-size: 22px;
    font-weight: 500;
    white-space: nowrap;

    @media screen and (max-width: 1200px) {
      padding: 3px 5px;
      font-size: 14px;
    }
  }

  .text {
    display: flex;
    align-items: center;
  }

  .qr-text {
    margin-top: auto;
    margin-bottom: auto;
    #qr {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
.lifetime {
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
.timer {
  display: grid;
  grid-template-columns: 60px 25px 60px;
  justify-items: center;
  font-size: 52px;

  .legend {
    font-size: 12px;
  }
}
.qr {
  width: 211px;
  height: 211px;
  border-radius: 15px;
}
.panel-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 10px;

  @media screen and (max-width: 1200px) {
    margin-top: 6px;
  }
}
.text-place {
  height: 20px;

  .error {
    font-size: 16px;
    color: red;
    @media screen and (max-width: 1200px) {
      text-align: center;
      font-size: 10px;
    }
  }
}
.metamask {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  width: 50%;
  padding: 5px 39px;
  font-size: 20px;

  border-radius: 10px;
  border: 1.5px dashed #2aba7c;
  color: #2aba7c;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    padding: 3px 10px;
    font-size: 12px;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
