<template>
  <div class="pay-fail-back">
    {{ $t("payAdv.fail") }}
  </div>
</template>

<script>
export default {
  name: "Fail"
};
</script>

<style scoped>
.pay-fail-back {
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #1F3A43;
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  font-size: 58px;
}
</style>
