<template>
  <div
    class="popper-container"
    @click="show=!show"
    @mouseleave="show=false"
  >
    <slot />
    <div
      v-if="show"
      class="question-popper"
    >
      {{ props.text }}
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  text: {
    type: String,
    default: ""
  }
});
let show = ref(false);

</script>

<style scoped lang="scss">
.popper-container {
  z-index: 999;
  position: relative;
}
.question-popper {
  width: 200px;
  position: absolute;
  top: -30px;
  left: 25px;
  border-radius: 10px;
  border: 1px solid silver;
  background-color: black;
  color: white;
  padding: 10px;
  font-size: 14px;
  z-index: 1000;
  text-align: left;
  @media screen and (max-width: 1600px) {
    top: 0;
    left: -250px;
  }
}
</style>
