<template>
  <div class="system-wrap">
    <div class="system">
      <img
        class="system-part decoration"
        src="/img/system/decoration.svg"
      >

      <img
        class="system-part system-img"
        src="/img/system/pc.png"
      >
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.system {
  position: relative;
  width: 632px;

  pointer-events: none;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 65vw;
  }

  &-wrap {
    width: 632px;
    margin-right: 200px;

    @media screen and (max-width: 800px) {
      width: auto;
      margin-right: 0;
    }
  }

  &-part {
    position: absolute;

    &.decoration {
      top: -160px;
      left: -478px;

      @media screen and (max-width: 1200px) {
        top: -300px;
      }

      @media screen and (max-width: 800px) {
        top: -500px;
        left: -652px;
      }
    }

    &.decoration-small {
      top: -23px;
    }
  }

  &-img {
    height: 500px;

    @media screen and (max-width: 1500px) {
      height: 440px;
    }

    @media screen and (max-width: 800px) {
      height: 50vw;
    }
  }
}
</style>
