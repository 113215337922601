<template>
    <iframe
      width="100%"
      height="580"
      src="https://www.youtube.com/embed/Zzm7UcAhfq8?si=mNbeTbgz0w0zzpqn"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer;
      autoplay;
      clipboard-write;
      encrypted-media;
      gyroscope;
      picture-in-picture;
      web-share"
      allowfullscreen>
    </iframe>
</template>

<script>
export default {
  name: "Tutorial"
};
</script>

<style scoped>

</style>
