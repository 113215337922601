<template>
  <section class="roadmap-section">
    <div class="content-wrapper">
      <h1>{{ $t("roadmapSection.title") }}</h1>
      <div class="roadmap-path desktop">
        <img
          src="/img/roadmap/roadmap-path.png"
          alt="roadmap-path"
        >
        <div class="roadmap-path-grid">
          <div
            v-for="(stage, index) in stages"
            :key="index"
            class="stage-wrapper"
          >
            <div class="date">
              {{ stage.date }}
            </div>
            <div class="label">
              {{ stage.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="roadmap-path mobile">
        <img
          src="/img/roadmap/roadmap-path-mobile.png"
          alt="roadmap-path"
        >
        <div class="roadmap-path-column">
          <div
            v-for="(stage, index) in mobileStages"
            :key="index"
            class="stage-wrapper"
          >
            <div class="date">
              {{ stage.date }}
            </div>
            <div class="label">
              {{ stage.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

const stages = computed(() => {
  let stages = [];
  for (let index = 1; index < 16; index++) {
    stages.push({
      date: t(`roadmapSection.${index}stage.date`),
      label: t(`roadmapSection.${index}stage.label`)
    });
  }
  return stages;
});
const mobileStages = computed(() => {
  return [
    {
      date: t("roadmapSection.1stage.date"),
      label: t("roadmapSection.1stage.label")
    },
    {
      date: t("roadmapSection.2stage.date"),
      label: t("roadmapSection.2stage.label")
    },
    {
      date: t("roadmapSection.3stage.date"),
      label: t("roadmapSection.3stage.label")
    },
    {
      date: t("roadmapSection.6stage.date"),
      label: t("roadmapSection.6stage.label")
    },
    {
      date: t("roadmapSection.5stage.date"),
      label: t("roadmapSection.5stage.label")
    },
    {
      date: t("roadmapSection.4stage.date"),
      label: t("roadmapSection.4stage.label")
    },
    {
      date: t("roadmapSection.7stage.date"),
      label: t("roadmapSection.7stage.label")
    },
    {
      date: t("roadmapSection.8stage.date"),
      label: t("roadmapSection.8stage.label")
    },
    {
      date: t("roadmapSection.9stage.date"),
      label: t("roadmapSection.9stage.label")
    },
    {
      date: t("roadmapSection.12stage.date"),
      label: t("roadmapSection.12stage.label")
    },
    {
      date: t("roadmapSection.11stage.date"),
      label: t("roadmapSection.11stage.label")
    },
    {
      date: t("roadmapSection.10stage.date"),
      label: t("roadmapSection.10stage.label")
    },
    {
      date: t("roadmapSection.13stage.date"),
      label: t("roadmapSection.13stage.label")
    },
    {
      date: t("roadmapSection.14stage.date"),
      label: t("roadmapSection.14stage.label")
    },
    {
      date: t("roadmapSection.15stage.date"),
      label: t("roadmapSection.15stage.label")
    }
  ];
});
</script>

<style lang="scss" scoped>
.roadmap {
  &-section {
    padding-top: 8vw;
    padding-bottom: 12vw;
    h1 {
      font-size: 5vw;
      font-family: Inter SemiBold;
      padding-left: 8vw;
      margin-bottom: 34px;
    }
  }
  &-path {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: auto;
      @media screen and (max-width: 850px) {
        display: none;
      }
    }
    &-grid {
      position: absolute;
      top: 0;
      display: grid;
      grid-template-columns: 30% 40% 30%;
      row-gap: 2.5vw;
      padding-left: 14vw;
      padding-right: 24vw;
      column-gap: 6vw;
      .stage-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: 400px;
        height: 8vw;
        justify-content: center;
        .date {
          font-size: 1vw;
        }
        .label {
          font-size: 1.4vw;
          font-family: Inter SemiBold;
        }
      }
      @media screen and (max-width: 850px) {
        display: none;
      }
    }

    &-column {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      gap: 5vw;
      .stage-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 78vw;
        height: 17vw;
        justify-content: center;
        padding-left: 5vw;
        &:nth-child(10) {
          height: 22.5vw;
        }
        &:nth-child(14),
        &:nth-child(15) {
          height: 22.5vw;
        }
        .date {
          font-size: 2.5vw;
        }
        .label {
          font-size: 4vw;
          font-family: Inter SemiBold;
        }
      }
    }

    &.mobile {
      display: none;
      @media screen and (max-width: 850px) {
        display: block;
        padding: 0 7vw;
      }
      img {
        width: 86vw;
        height: auto;
        @media screen and (max-width: 850px) {
          display: block;
        }
      }
    }
  }
}
</style>
