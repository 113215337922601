<template>
  <div class="rp-background">
    <span
      v-if="error === null"
      class="rp-message"
    >
      {{  $t("resetPassword.message") }}
    </span>
    <span
      v-else
      class="rp-message"
      :error="error"
    >
      {{ error ? $t("resetPassword.error") : $t("resetPassword.success") }}
      <AuButton
        v-if="!error"
        center
        @click="goToLogin"
      >
        {{ $t("resetPassword.login") }}
      </AuButton>
    </span>
  </div>
</template>

<script setup>
import axios from "axios";
import Trans from "@/localization/translation";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router/dist/vue-router";

const router = useRouter();
const goToLogin = () => {
  router.push({ name: "Login", params: { locale: Trans.currentLocale } });
};

const props = defineProps({
  code: {
    type: String,
    default: ""
  }
});
const error = ref(null);
onMounted(async () => {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json"
  };
  const params = {
    code: props.code
  };
  try {
    await axios.post("/api/users/confirm_reset_password", params, { headers });
    error.value = false;
  }
  catch (e) {
    error.value = true;
  }
});
</script>

<style scoped lang="scss">
.rp-background {
  background: #1F3A43;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.rp-message {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 150px;
  font-size: 30px;
  color: white;
  &[error="true"] {
  color: red;
  }
}
</style>
