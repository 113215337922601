<template>
  <div class="clock-wrap">
    <div class="clock">
      <div class="clock-part clock-big clock-img">
        <img
          class="clock-part decoration"
          src="/img/clock/clock-decoration.svg"
        >

        <img
          class="clock-part"
          src="/img/clock/clock.png"
        >
      </div>

      <div class="clock-part clock-small clock-img">
        <img
          class="clock-part decoration-small"
          src="/img/clock/clock-decoration-small.svg"
        >

        <img
          class="clock-part"
          src="/img/clock/clock-small.png"
        >
      </div>

      <div class="clock-part inner">
        <span class="green-label">{{ $t("clockSection.clock.header") }}</span>

        <div class="time">
          <span>
            {{ formatTime(diffDays) }}
          </span>
          :
          <span>
            {{ formatTime(diffHours) }}
          </span>
          :
          <span>
            {{ formatTime(diffMin) }}
          </span>
          :
          <span>
            {{ formatTime(diffSec) }}
          </span>
        </div>

        <div class="time-labels">
          <span class="time-labels__days">{{ $t("clockSection.clock.days") }}</span>
          <span class="time-labels__hours">{{ $t("clockSection.clock.hours") }}</span>
          <span class="time-labels__min">{{ $t("clockSection.clock.min") }}</span>
          <span class="time-labels__sec">{{ $t("clockSection.clock.sec") }}</span>
        </div>

        <AuButton
          shadow
          padding="12px 30px"
          font-size="18px"
          class="big-button"
          @click="$emit('showModal')"
        >
          {{ $t("clockSection.clock.buy") }}
        </AuButton>

        <AuButton
          shadow
          padding="6px 15px"
          font-size="9px"
          height="30px"
          class="small-button"
          @click="$emit('showModal')"
        >
          {{ $t("clockSection.clock.buy") }}
        </AuButton>

        <div class="raised">
          <span class="raised__label">
            {{ $t("clockSection.clock.raised") }}
          </span>

          <span class="raised__count">
            {{ raised }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  day: {
    type: Number,
    default: 31
  },
  month: {
    type: Number,
    default: 2
  }
});

const diffDays = ref(0);
const diffHours = ref(0);
const diffMin = ref(0);
const diffSec = ref(0);

const raised = computed(() => process.env.VUE_APP_RAISED);

const getNow = () => {
  const releaseDate = new Date(Date.UTC(2024, props.month, props.day, 12, 0, 0));

  let timeDiff = Math.floor(releaseDate.getTime() - new Date().getTime()) / 1000;

  if (timeDiff > 0) {
    timeDiff = Math.abs(timeDiff);
  }
  else {
    return;
  }

  diffDays.value = Math.floor(timeDiff / (3600 * 24));

  timeDiff = timeDiff - diffDays.value * (3600 * 24);

  diffHours.value = Math.floor(timeDiff / (3600));

  timeDiff = timeDiff - diffHours.value * (3600);

  diffMin.value = Math.floor(timeDiff / (60));

  timeDiff = timeDiff - diffMin.value * (60);

  diffSec.value = Math.floor(timeDiff);
};

const formatTime = time => {
  return time.toString().padStart(2, "0");
};

getNow();
setInterval(getNow, 1000);
</script>

<style scoped lang="scss">
.clock {
  position: relative;
  width: 632px;
  height: 632px;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 420px;
  }

  &-wrap {
    width: 632px;

    @media screen and (max-width: 800px) {
      width: auto;
    }
  }

  .clock-big {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .clock-small {
    display: none;

    @media screen and (max-width: 800px) {
      width: 100%;
      top: 24%;
      z-index: 150;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-img {
    pointer-events: none;
  }

  &-part {
    position: absolute;

    &.decoration {
      top: -160px;
      left: -478px;
    }

    &.decoration-small {
      top: -23px;
    }

    &.inner {
      top: 33%;
      z-index: 150;

      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      height: 100%;

      .small-button {
        display: none;
      }

      @media screen and (min-width: 800px) {
        right: -7%;
      }

      @media screen and (max-width: 800px) {
        top: 45%;

        .small-button {
          display: block;
        }

        .big-button {
          display: none;
        }
      }

    }
  }
}

.green-label {
  color: #2ABA7C;
  font-size: 20px;

  margin-bottom: 40px;

  @media screen and (max-width: 800px) {
    white-space: nowrap;
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.time {
  color: #2B2B2B;
  text-align: center;
  text-shadow: 0px 14px 6px rgba(178, 202, 217, 0.71);
  font-size: 69px;
  text-indent: 0px;
  letter-spacing: -1px;
  display: flex;
  width: 400px;
  margin-left: 25px;
  margin-bottom: 21px;
  line-height: 32px;

  @media screen and (max-width: 800px) {
    font-size: 35px;
    width: 200px;
    margin-bottom: 0px;
  }

  &-labels {
    color: #000;
    text-align: center;
    font-size: 12px;
    width: 400px;
    display: flex;
    line-height: 32px;
    margin-bottom: 25px;

    @media screen and (max-width: 800px) {
      font-size: 9px;
      width: 200px;
      margin-bottom: 12px;

      &__days {
        margin-right: 23px;
        margin-left: 20px;
      }

      &__hours {
        margin-right: 20px;
      }

      &__min {
        margin-right: 30px;
      }
    }

    @media screen and (min-width: 801px) {
      &__days {
        margin-right: 61px;
        margin-left: 40px;
      }

      &__hours {
        margin-right: 57px;
      }

      &__min {
        margin-right: 70px;
      }
    }
  }
}

.raised {
  margin-top: 45px;

  width: 240px;
  height: 77px;

  border-left: 1px solid black;
  border-right: 1px solid black;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;

  @media screen and (max-width: 800px) {
    margin-top: 20px;
    width: 120px;
    height: 35px;
    gap: 5px;

    &__label {
      margin-top: 10px;
      font-size: 9px !important;
      line-height: 5px !important;
    }

    &__count {
      font-size: 12px !important;
      line-height: 5px !important;
    }
  }

  &__label {
    color: #1F3A43;
    font-size: 18px;
    line-height: 18px;
  }

  &__count {
    color: #1F3A43;
    font-size: 33px;
    font-weight: 500;
    line-height: 33px;
  }
}
.faq-link {
  font-size: 18px;
  color: #2ABA7C;
  cursor: pointer;
  text-decoration: underline;
  @media screen and (max-width: 800px) {
    margin-top: 5px;
  }
}
</style>
