import AuButton from "./AuButton.vue";
import AuCheckbox from "./AuCheckbox.vue";
import AuIcon from "./AuIcon.vue";
import AuInput from "./AuInput.vue";
import AuPagination from "./AuPagination.vue";
import AuPopper from "./AuPopper.vue";
import AuSelect from "./AuSelect.vue";
import AuTable from "./AuTable.vue";

const Components = {
  AuButton,
  AuCheckbox,
  AuIcon,
  AuInput,
  AuSelect,
  AuTable,
  AuPagination,
  AuPopper
};

export default Components;
