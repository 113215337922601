<template>
  <div class="side-menu">
    <img
      class="poligon"
      src="/img/cabinet/menu-poligon.svg"
    >

    <div class="header">
      <AuSelect
        css-vars="--width: 160px"
        class="locale"
        :value="currentLocale"
        :placeholder="currentLocale.toUpperCase()"
        :options="countryOptions"
        :image="true"
        @input="(v) => switchLanguage(v)"
      />

      <img
        class="exit"
        src="/img/cabinet/exit.svg"
        @click="$emit('close')"
      >
    </div>

    <div class="person">
      <img
        src="/img/cabinet/person.svg"
        alt="person"
      >
      <span class="name">{{ userName }}</span>
      <AuButton
        height="55px"
        font-size="18px"
        padding="23px 30px"
        background-color="#00000000"
        text-color="#2ABA7C"
        center
        @click="logOut"
      >
        {{ $t("topMenu.exit") }}
        <img
          src="/img/cabinet/arrow.svg"
          alt="arrow"
        >
      </AuButton>
    </div>

    <div class="address-block">
      <div>
        <div class="title">
          {{ $t("topMenu.address") }}
        </div>
        <div class="address">
          {{ shortAdr }}
        </div>
      </div>
      <AuButton
        height="55px"
        font-size="18px"
        padding="23px 30px"
        center
        @click="goToSettings"
      >
        {{ changeButton }}
      </AuButton>
      <div
        v-if="showTriangle"
        class="triangle"
      >
        <img
          class="warning"
          src="/img/cabinet/triangle.svg"
        >
        <div
          v-if="showMessage"
          class="message"
        >
          {{ $t("topMenu.message") }}
        </div>
      </div>
    </div>

    <div
      v-if="showTriangle"
      class="triangle"
    >
      <img
        class="warning"
        src="/img/cabinet/triangle.svg"
      >
      <div
        v-if="showMessage"
        class="message"
      >
        {{ $t("topMenu.message") }}
      </div>
    </div>

    <div
      class="item"
      :active="route.name==='dashboard'"
      @click="router.push({ name: 'dashboard' }); $emit('close')"
    >
      <img :src="getIconName('dashboard', route.name==='dashboard')">
      {{ $t("leftMenu.dashboard") }}
    </div>
    <div
      class="item"
      @click="openModal(); $emit('close')"
    >
      <img :src="getIconName('buy', false)">
      {{ $t("leftMenu.buy") }}
    </div>
    <div
      class="item"
      :active="route.name==='Transactions'"
      @click="router.push({ name: 'Transactions' }); $emit('close')"
    >
      <img :src="getIconName('transaction', false)">
      {{ $t("leftMenu.transaction") }}
    </div>
    <div
      class="item"
      :active="route.name==='referral info'"
      @click="router.push({ name: 'referral info' }); $emit('close')"
    >
      <img :src="getIconName('referrals', route.name==='referral info')">
      {{ $t("leftMenu.referral") }}
    </div>
    <div
      class="item"
      :active="route.name==='staking'"
      @click="router.push({ name: 'staking' }); $emit('close')"
    >
      <img :src="getIconName('staking', false)">
      {{ $t("leftMenu.staking") }}
    </div>
    <div
      class="item"
      :active="route.name==='vesting'"
      @click="router.push({ name: 'vesting' }); $emit('close')"
    >
      <img :src="getIconName('vesting', false)">
      {{ $t("leftMenu.vesting") }}
    </div>
    <div
      class="item"
      :active="route.name==='tutorial'"
      @click="router.push({ name: 'tutorial' }); $emit('close')"
    >
      <img :src="getIconName('tutorials', route.name==='tutorials')">
      {{ $t("leftMenu.tutorials") }}
    </div>
    <div
      class="item"
      @click="goToSupport(); $emit('close')"
    >
      <img :src="getIconName('support', false)">
      {{ $t("leftMenu.support") }}
    </div>
    <div
      class="item"
      :active="route.name==='settings'"
      @click="router.push({ name: 'settings' }); $emit('close')"
    >
      <img :src="getIconName('settings', route.name==='settings')">
      {{ $t("leftMenu.settings") }}
    </div>

    <div class="socials">
      <AuIcon link="https://www.linkedin.com/company/autcapital">
        <img
          class="socials-icon"
          src="/img/socials/linkedin.svg"
          alt="linkedin"
        >
      </AuIcon>
      <AuIcon link="https://discord.com/invite/aZVj8q2hmT">
        <img
          class="socials-icon"
          src="/img/socials/discord.svg"
          alt="discord"
        >
      </AuIcon>
      <AuIcon link="https://t.me/AUT_Community_EN">
        <img
          class="socials-icon"
          src="/img/socials/telegram.svg"
          alt="viber"
        >
      </AuIcon>
      <AuIcon link="https://twitter.com/AutCapital">
        <img
          class="socials-icon"
          src="/img/socials/x.svg"
          alt="x"
        >
      </AuIcon>
    </div>
  </div>

  <div class="backdrop" />
</template>

<script setup>
import _ from "lodash";
import isTokenSaleClose from "@/help/sale";

import TokenClose from "@/components/TokenClose";
import TokensBuy from "@/components/TokensBuy";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useModal } from "vue-final-modal";
import { useRoute, useRouter } from "vue-router";
const { t } = new useI18n();

const emit = defineEmits(["exit"]);

const props = defineProps({
  address: {
    type: String,
    default: ""
  },
  email: {
    type: String,
    default: ""
  }
});
const route = useRoute();
const router = useRouter();
const goToSettings = () => {
  router.push({ name: "settings" });
  emit("close");
};

const showTriangle = computed(() => {
  if (!isConnected.value || props.address === "-") {
    return false;
  }
  return !(metamaskAddress == props.address);
});

const isConnected = ref(false);
const showMessage = ref(false);
let metamaskAddress = null;

const shortAdr = computed(() => {
  return props.address === "-" ? t("topMenu.addressAut") : props.address;
});
const changeButton = computed(() => {
  return props.address === "-" ? t("topMenu.setAddress") : t("topMenu.change");
});

import Trans from "@/localization/translation";

const currentLocale = computed(() => {
  return Trans.currentLocale;
});

const supportedLocales = ref(Trans.supportedLocales);

const countryOptions = computed(() => {
  return _.map(supportedLocales.value, l => {
    return {
      display: l.toUpperCase(),
      value: l,
      image: `/img/flags/${l}.svg`
    };
  });
});

const switchLanguage = async newLocale => {
  await Trans.switchLanguage(newLocale);

  try {
    await router.replace({ params: { locale: newLocale } });
    document.title = t("title");
  }
  catch (e) {
    console.log(e);
    router.push("/");
  }
};

import auth from "@/auth";

const userName = computed(() => {
  let ret = "";
  try {
    ret = JSON.parse(auth.remember());
  }
  catch (e) {
    return "";
  }
  return ret?.username ? ret.username : "";
});

const logOut = () => {
  auth.logout();
};

const getIconName = (iconName, isActive) => {
  return "/img/cabinet/" + iconName + (isActive ? "_active.svg" : ".svg");
};

const goToSupport = () => {
  window.open("https://t.me/+i0KV3Kwmt7dhMDEy", "_blank").focus();
};

const openModal = () => {
  const { open, close } = useModal({
    component: isTokenSaleClose() ? TokenClose : TokensBuy,
    attrs: {
      initEmail: props.email,
      initAddress: props.address,
      onClose() {
        close();
      }
    }
  });

  open();
};
</script>

<style scoped lang="scss">
.backdrop {
  background: #0f1b1fad;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  top: 0;
}

.side-menu {
  overflow: auto;

  width: 600px;
  height: 100vh;
  padding: 0px 40px;
  background-color: #1F3A43;

  @media (max-width: 800px) {
    max-width: 600px;
    width: calc(100% - 58px);

    padding: 0px 30px;
  }

  position: absolute;
  top: 0;
  right: 0;

  z-index: 1000;

  .exit {
    cursor: pointer;
  }

  .address-block {
    font-size: 20px;

    margin: 40px 0;

    .address {
      margin-top: 10px;
      margin-bottom: 20px;

      @media (max-width: 800px) {
        text-overflow: ellipsis;
        width: 155px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    @media (max-width: 800px) {
      font-size: 14px;
      margin: 20px 0;

      display: flex;

      justify-content: space-between;
    }
  }

  .socials {
    display: flex;
    gap: 25px;
    margin: 30px 0px;

    &-icon {
      width: 42px;
      height: 42px;
    }
  }

  .metamask-btn {
    width: 193px;
    height: 55px;
    border-radius: 10px;
    background-color: #FFF3C9;
    color: #763D16;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &[disable="true"] {
      background-color: #cccccc;
      cursor: default;
    }

    @media (max-width: 800px) {
      font-size: 16px;

      width: auto;
      height: 30px;

      padding: 5px 10px;

      img {
        height: 20px;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    align-items: baseline;

    margin-top: 20px;

    .locale {
      margin-left: -25px;
    }
  }

  .poligon {
    position: absolute;
    top: 0;
    right: 0;

    pointer-events: none;

    @media (max-width: 800px) {
      height: 1000px;
    }
  }

  .person {
    display: none;

    @media (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 10px;

      margin-top: 20px;
      padding-bottom: 20px;

      border-bottom: #39626E 1px solid;
    }

    .name {
      text-overflow: ellipsis;
      width: 175px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .item {
    position: relative;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    border-bottom: 1px solid #416C7A;
    padding: 30px 0;
    display: flex;
    gap: 18px;
    align-items: center;
    cursor: pointer;
    &[active="true"] {
      color: #2ABA7C;
      border-bottom: 1px solid #2ABA7C;
    }
    &[end="true"]{
      border-bottom: none;
    }

    @media (max-width: 800px) {
      font-size: 22px;
      padding: 20px 0;
    }
  }
  .no-active {
    color: #ababab;
    border-bottom: 1px solid #ababab;
    &[active="true"] {
      color: #ababab;
      border-bottom: 1px solid #ababab;
    }
  }

  .triangle {
    position: relative;
    .warning {
      width: 30px;
      height: 30px;
    }
    .message {
      z-index: 100;
      width: 250px;
      top: 30px;
      left: 30px;
      padding: 5px;
      position: absolute;
      border: 1px solid grey;
      background-color: rgba(0,0,0, 0.8);
      color: white;
      line-height: 1.5;
    }
  }
}
.custom-popper {
  position: absolute;
  right: 0;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-radius: 12px;
  color: #9b9b9b;

}
</style>
