<template>
  <div class="dashboard">
    <Referral :user="userData" />
    <TokenInfo class="token-info" />
    <ReferralEvent :referral-events="referralEvents" />

    <ValueAut />
    <div class="line">
      <BuyAut
        :main-balance="mainBalance"
        :referral-balance="referralBalance"
        :usdt-referral-balance="usdtCashbackBalance"
        :vesting-balance="vestingBalance"
        :auto-staking-balance="autoStakingBalance"
        :address="address"
      />
      <Structure
        :main-balance="mainBalance"
        :referral-balance="referralBalance"
        :vesting-balance="vestingBalance"
      />
    </div>
    <!-- <Dynamics /> -->
    <LastTransactions :transactions="transactions" />
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BuyAut from "@/components/cabinet/Dashboard/BuyAut";
import LastTransactions from "@/components/cabinet/Dashboard/LastTransactions";
import Referral from "@/components/cabinet/Dashboard/Referral";
import ReferralEvent from "@/components/cabinet/Dashboard/ReferralEvent";
import Structure from "@/components/cabinet/Dashboard/Structure";
import TokenInfo from "@/components/cabinet/TokenInfo";
import ValueAut from "@/components/cabinet/Dashboard/ValueAut";
import { onMounted, ref } from "vue";

const mainBalance = ref("0");
const referralBalance = ref("0");
const usdtCashbackBalance = ref("0");
const vestingBalance = ref("0");
const autoStakingBalance = ref("0");
const userData = ref({});
const address = ref("");
const transactions = ref([]);
const referralEvents = ref([]);

const getData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    mainBalance.value = resp.data.mainBalance;
    referralBalance.value = resp.data.referralBalance;
    usdtCashbackBalance.value = resp.data.usdtCashbackBalance;
    vestingBalance.value = resp.data.vestingBalance;
    autoStakingBalance.value = resp.data?.autoStakingBalance;
    address.value = _.get(resp.data, "ethAddress", "");
    userData.value = resp.data;
  }
  catch (e) {
    console.log(e);
  }
};

const getTransactions = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      page: 1,
      itemsPerPage: 10,
      userId: authMem?.id
    };
    const resp = await axios.get("/api/transactions", {
      headers: headers,
      params: params
    });
    transactions.value = resp.data["hydra:member"];
  }
  catch (e) {
    console.log(e);
  }
};

const getReferralEvent = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const resp = await axios.get("/api/exchange_pays/statistics", {
      headers: headers
    });
    referralEvents.value = resp.data;
  }
  catch (e) {
    console.log(e);
  }
};

onMounted(async () => {
  await getData();
  await getTransactions();
  await getReferralEvent();
});
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .token-info {
    display: none;

    @media screen and (max-width: 1600px) {
      display: block;
    }
  }
}
.line {
  display: flex;
  gap: 40px;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
}
</style>
