<template>
  <div>
    <div class="ref-title">
      {{ $t("referralInfo.title") }}
    </div>
    <div class="ref-panel">
      <div class="ref-switcher">
        <span
          class="ref-panel-title"
          :active="showEvents"
          @click="showEvents=true"
        >
          {{ $t("referralInfo.event") }}
        </span>
        <span
          class="ref-panel-title"
          :active="!showEvents"
          @click="showEvents=false"
        >
          {{ $t("referralInfo.list") }}
        </span>
      </div>
      <div
        v-if="showEvents"
        class="table-pagination"
      >
        <AuTable
          :columns="eventsColumns"
          :items="eventsItems"
        >
          <template #amount="{ item }">
            {{ item.amount }}
            <span v-if="item[symbolType]==='confirm'"> AUT</span>
            <span v-if="item[symbolType]==='pay'"> ₮</span>
          </template>
        </AuTable>
        <AuPagination
          v-if="eventsTotalItems > itemsPerPage"
          :total-items="eventsTotalItems"
          :items-per-page="itemsPerPage"
          @changePage="changePageEvents"
        />
      </div>
      <div
        v-else
        class="table-pagination"
      >
        <AuTable
          :columns="usersColumns"
          :items="usersItems"
        />
        <AuPagination
          v-if="usersTotalItems > itemsPerPage"
          :total-items="usersTotalItems"
          :items-per-page="itemsPerPage"
          @changePage="changePageUsers"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BigNumber from "bignumber.js";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";

const { t } = useI18n({});

const showEvents = ref(true);

const eventsPage = ref(1);
const usersPage = ref(1);
const itemsPerPage = ref(30);
const eventsData = ref([]);
const eventsTotalItems = ref(0);
const usersData = ref([]);
const usersTotalItems = ref(0);
const symbolType = ref(Symbol("type"));

onMounted(async () => {
  await getReferralEvent();
  await getReferralUsers();
});

const getReferralEvent = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      page: eventsPage.value,
      itemsPerPage: itemsPerPage.value
    };
    const resp = await axios.get("/api/referal_events", {
      headers: headers,
      params: params
    });
    eventsData.value = resp.data["hydra:member"];
    eventsTotalItems.value = resp.data["hydra:totalItems"];
  }
  catch (e) {
    console.log(e);
  }
};

const eventsColumns = computed(() => {
  return [{ title: t("referralInfo.eventColumn1"), name: "email" },
    { title: t("referralInfo.eventColumn2"), name: "date" },
    { title: t("referralInfo.eventColumn3"), name: "type" },
    { title: t("referralInfo.eventColumn6"), name: "amount", bold: true }];
});
const eventName = computed(() => {
  return {
    pay: t("referralEvent.pay"),
    confirm: t("referralEvent.confirm"),
    register: t("referralEvent.register"),
    view: t("referralEvent.transition")
  };
});
const eventsItems = computed(() => {
  return _.map(eventsData.value, item => {
    const amount = BigNumber(item.amount);
    const ret = {
      email: item.email || "-",
      date: getDate(item.createdAt),
      type: _.get(eventName.value, item.type, "-"),
      user: item.user,
      refUser: item.refUser,
      amount: amount.isNaN() ? "-" : amount.toFixed(2) + (item.type === "confirm" ? " AUT" : " $")
    };
    ret[symbolType.value] = item.type;
    return ret;
  });
});
const changePageEvents = async activePage => {
  eventsPage.value = activePage;
  await getReferralEvent();
};

const getReferralUsers = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      page: usersPage.value,
      itemsPerPage: itemsPerPage.value,
      userId: authMem?.id
    };
    const resp = await axios.get("/api/users/my_referal_stat", {
      headers: headers,
      params: params
    });
    usersData.value = resp.data["hydra:member"];
    usersTotalItems.value = resp.data["hydra:totalItems"];
  }
  catch (e) {
    console.log(e);
  }
};
const getDate = createdAt => {
  if (_.isNil(createdAt)) {
    return;
  }
  const date = new Date(createdAt);
  return moment(date).format("DD-MM-YYYY HH:mm:ss");
};
const usersColumns = computed(() => {
  return [{ title: t("referralInfo.userColumn1"), name: "email" },
    { title: t("referralInfo.userColumn2"), name: "paymentCount" },
    { title: t("referralInfo.userColumn3"), name: "paymentSum" },
    { title: t("referralInfo.userColumn4"), name: "commissionSum" },
    { title: t("referralInfo.userColumn5"), name: "myCommissionSum" }];
});
const usersItems = computed(() => {
  return _.map(usersData.value, item => {
    const paymentSum = BigNumber(item.paymentSum);
    const commissionSum = BigNumber(item.commissionSum);
    const myCommissionSum = BigNumber(item.myCommissionSum);

    return {
      email: item.email,
      paymentCount: item.paymentCount,
      paymentSum: paymentSum.isNaN() ? "-" : "$" + paymentSum.toFixed(2),
      commissionSum: commissionSum.isNaN() ? "-" : "$" + commissionSum.toFixed(2),
      myCommissionSum: myCommissionSum.isNaN() ? "-" : "$" + myCommissionSum.toFixed(2)
    };
  });
});
const changePageUsers = async activePage => {
  usersPage.value = activePage;
  await getReferralUsers();
};
</script>

<style lang="scss" scoped>
.ref-title {
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 40px;
}
.ref-panel {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }
}
.ref-switcher {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.ref-panel-title {
  font-weight: 400;
  font-size: 38px;
  cursor: pointer;
  &[active="true"] {
    color: #2ABA7C;
  }
}

.table-pagination {
  display: flex;
  flex-direction: column;

  gap: 10px;
}
</style>
