<template>
  <div class="structure">
    <div class="title">
      {{ $t("structure.title") }}
    </div>
    <Apexchart
      v-if="showChart"
      class="chart-wrapper"
      :series="series"
      :options="options"
      height="100%"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n/dist/vue-i18n";
const { t } = useI18n({});

const props = defineProps({
  mainBalance: {
    type: String,
    default: "0"
  },
  referralBalance: {
    type: String,
    default: "0"
  },
  vestingBalance: {
    type: String,
    default: "0"
  }
});

const series = computed(() => {
  let r = Number(props.referralBalance);
  let v = Number(props.vestingBalance);
  let m = Number(props.mainBalance);
  r = Number.isNaN(r) ? 0 : r;
  v = Number.isNaN(v) ? 0 : v;
  m = Number.isNaN(m) ? 0 : m;
  return [m, v, r];
});

const showChart = computed(() => {
  return Number(props.referralBalance) || Number(props.vestingBalance) || Number(props.mainBalance);
});
const options = computed(() => {
  return {
    labels: [t("structure.wallet"), t("structure.vesting"), t("structure.referral")],
    chart: {
      type: "donut"
    },
    colors: ["#74AADD", "#7BCCAF", "#E1A0EB"],
    plotOptions: {
      pie: {
        expandOnClick: false
      }
    },
    dataLabels: {
      style: {
        fontSize: "18px",
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    legend: {
      position: "bottom",
      fontSize: "22px",
      fontWeight: 400,
      horizontalAlign: "left",
      itemMargin: {
        vertical: 15
      },
      markers: {
        width: 28,
        height: 28
      }
    }
  };
});
</script>

<style lang="scss">
.structure {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: white;
  padding: 40px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  .title {
    font-weight: 600;
    font-size: 2vw;
    margin-bottom: 40px;

    @media (max-width: 1600px) {
      font-size: 2.5vw;
    }

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
  .chart-wrapper {
    height: 400px;

    .apexcharts-legend {
      flex-direction: column;
      &-series {
        display: flex !important;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
</style>
