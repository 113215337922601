<template>
  <section class="contract-section container">
    <div class="title">
      <h1>
        {{ $t("smartContract.title") }}
      </h1>

      <div class="text">
        <span v-html="$t('smartContract.audit')" />
        <span v-html="$t('smartContract.report')" />
      </div>
    </div>

    <div class="corners">
      <img
        src="/img/contract/left-top.svg"
        alt="corner"
      >
      <img
        src="/img/contract/right-top.svg"
        alt="corner"
      >
      <img
        src="/img/contract/right-bottom.svg"
        alt="corner"
      >
      <img
        src="/img/contract/left-bottom.svg"
        alt="corner"
      >
      <div class="content-wrapper">
        <img
          src="/img/contract/binance-icon.svg"
          alt="binance"
        >
        <div class="info-wrapper">
          <h1>Smart contract address:</h1>
          <a
            href="https://bscscan.com/token/0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b"
            target="_blank"
          >
            <span class="address"> 0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b </span>
          </a>
          <div class="token-simbol-info">
            <span class="label"> Token Simbol: </span>
            <span> AUT </span>
          </div>
          <div class="decimals-info">
            <span class="label"> Decimals: </span>
            <span> 18 </span>
          </div>
        </div>
      </div>
      <div class="paladin-link-wrapper">
        <a
          href="https://paladinsec.co/project/autentic-utility-token"
          target="_blank"
        >
          <img
            src="/img/contract/paladin-logo.svg"
            alt="paladin-logo"
          >
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.contract-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  padding-top: 150px;

  position: relative;
  z-index: 2;

  .title {
    color: #1F3A43;;
    margin-bottom: 60px;
    width: 100%;

    h1 {
      font-size: 68px;
      margin-bottom: 30px;

      @media screen and (max-width: 1200px) {
        font-size: 32px;
      }
    }

    span {
      @media screen and (max-width: 1200px) {
        font-size: 22px;
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      gap: 5px;

      font-size: 28px;
      font-family: Inter Medium;

      a,
      a:visited  {
        color: #2ABA7C !important;
      }
    }
  }

  .corners {
    width: 100%;
    min-height: 389px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    img[alt="paladin-logo"] {
      width: 100%;
      @media screen and (max-width: 1200px) {
        width: 50%;
      }
    }
    .paladin-link-wrapper {
      @media screen and (max-width: 1200px) {
        a {
          display: flex;
        justify-content: center;
        align-items: center;
        }
      }
    }
    @media screen and (max-width: 1560px) {
      flex-direction: column;
      gap: 20px;
    }

    a {
      text-decoration: none;
    }

    img[alt="corner"] {
      position: absolute;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
      }
      &:nth-child(3) {
        bottom: 0;
        right: 0;
      }
      &:nth-child(4) {
        bottom: 0;
        left: 0;
      }
    }
    .content-wrapper {
      display: flex;
      gap: 50px;
      img {
        width: 182px;
        height: 182px;

        &[alt="binance"] {
          @media screen and (max-width: 1200px) {
            display: none;
          }
        }
      }
      .info-wrapper {
        color: #1f3a43;

        @media screen and (max-width: 1200px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        h1 {
          font-size: 48px;
          font-weight: 600;
          margin-bottom: 23px;
          font-family: Inter SemiBold;

          @media screen and (max-width: 1200px) {
            font-size: 26px;
          }
        }
        .address {
          font-size: 22px;
          font-weight: 600;
          display: block;
          margin-bottom: 12px;
          color: #1f3a43;

          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }
        }
        .token-simbol-info,
        .decimals-info {
          font-size: 22px;
          margin-bottom: 12px;

          @media screen and (max-width: 1200px) {
            font-size: 19px;
          }

          .label {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
