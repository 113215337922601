<template>
  <div class="aut-value">
    {{ $t("valueAut.title") }}
  </div>
</template>

<script>
export default {
  name: "ValueAut"
};
</script>

<style lang="scss" scoped>
.aut-value {
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  border-radius: 15px;
  background-color: white;
  font-weight: 600;
  font-size: 2vw;

  @media (max-width: 1600px) {
    font-size: 2.5vw;
  }

  @media (max-width: 800px) {
    display: none;
  }
}
</style>
