<template>
  <section class="container">
    <h1>
      Trusted by teams at
    </h1>

    <div class="teams">
      <img
        v-for="(team, index) in teams"
        :key="index"
        :src="`/img/trusted/${team}.png`"
        alt="team"
      >
    </div>
  </section>
</template>

<script setup>
const teams = ["1", "2", "3", "4"];
</script>

<style lang="scss" scopd>
.container {
  margin-bottom: 110px;

  h1 {
    font-size: 28px;
    font-family: Inter SemiBold;
    color: #1F3A43;

    margin-bottom: 20px;
  }

  .teams {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
}
</style>