<template>
  <VueFinalModal>
    <div class="modal">
      <img
        class="close"
        src="/img/modal/close-mobile.svg"
        alt=""
        @click="$emit('close')"
      >

      <div class="buttons-wrapper">
        <AuButton
          padding="14px"
          font-size="16px"
          center
          @click="openWhitepaper"
        >
          {{ $t("clockSection.buttons.whitepaper") }}
        </AuButton>
        <AuButton
          padding="14px"
          font-size="16px"
          transparent
          center
          @click="openLightpaper"
        >
          {{ $t("downloads.lightpaper") }}
        </AuButton>
        <AuButton
          padding="14px"
          font-size="16px"
          transparent
          center
          @click="openOnepager"
        >
          {{ $t("downloads.onepager") }}
        </AuButton>
        <AuButton
          transparent
          padding="14px"
          font-size="16px"
          center
          @click="showPresentation"
        >
          {{ $t("clockSection.buttons.presentation") }}
        </AuButton>
      </div>
    </div>
  </VueFinalModal>
</template>


<script setup>
import { VueFinalModal } from "vue-final-modal";

import Presentation from "@/components/Presentation.vue";
import { useModal } from "vue-final-modal";

const openWhitepaper = () => {
  window.open("wp_en.pdf", "_blank");
};
const openLightpaper = () => {
  window.open("lp_en.pdf", "_blank");
};
const openOnepager = () => {
  window.open("onepage_en.pdf", "_blank");
};

const showPresentation = () => {
  const { open, close } = useModal({
    component: Presentation,
    attrs: {
      onClose() {
        close();
      }
    }
  });

  open();
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  width: fit-content;
  height: fit-content;
  background: #daedf3;
  border-radius: 15px;
  background: linear-gradient(256deg, #daedf3 -2.62%, #fefeff 77.25%, #fff 77.25%);
  padding: 40px;
  @media screen and (max-width: 370px) {
    width: 100%;
    div.buttons-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }


  .buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1003;
  }
}
</style>