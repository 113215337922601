/* eslint-disable */
"use strict";
var e = require("vue"),
  n = {
    name: "LvProgressspinner",
    props: {
      strokeWidth: { type: String, default: "2" },
      fill: { type: String, default: "none" },
      animationDuration: { type: String, default: "2s" },
      size: { type: String, default: "75px" },
      color: { type: String, default: "#607c8a" },
    },
    computed: {
      svgStyle() {
        return { "animation-duration": this.animationDuration };
      },
      animStyle() {
        return { "animation-duration": this.animationDuration, stroke: this.color };
      },
    },
  };
!(function (e, n) {
  void 0 === n && (n = {});
  var t = n.insertAt;
  if (e && "undefined" != typeof document) {
    var r = document.head || document.getElementsByTagName("head")[0],
      s = document.createElement("style");
    (s.type = "text/css"), "top" === t && r.firstChild ? r.insertBefore(s, r.firstChild) : r.appendChild(s), s.styleSheet ? (s.styleSheet.cssText = e) : s.appendChild(document.createTextNode(e));
  }
})(
  "\n.lv-progress-spinner {\n    position: relative;\n    margin: 0 auto;\n    /* width: 100px; */\n    /* height: 100px; */\n    display: inline-block;\n}\n.lv-progress-spinner::before {\n     content: '';\n     display: block;\n     padding-top: 100%;\n}\n.lv-progress-spinner-svg {\n    animation: lv-progress-spinner-rotate 2s linear infinite;\n    height: 100%;\n    transform-origin: center center;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin: auto;\n}\n.lv-progress-spinner-circle {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: 0;\n    stroke: #d62d20;\n    animation: lv-progress-spinner-dash 1.5s ease-in-out infinite;\n     /* lv-progress-spinner-color 6s ease-in-out infinite; */\n    stroke-linecap: round;\n}\n@keyframes lv-progress-spinner-rotate {\n100% {\n        transform: rotate(360deg);\n}\n}\n@keyframes lv-progress-spinner-dash {\n0% {\n        stroke-dasharray: 1, 200;\n        stroke-dashoffset: 0;\n}\n50% {\n        stroke-dasharray: 89, 200;\n        stroke-dashoffset: -35px;\n}\n100% {\n        stroke-dasharray: 89, 200;\n        stroke-dashoffset: -124px;\n}\n}\n@keyframes lv-progress-spinner-color {\n100%,\n    0% {\n        stroke: #d62d20;\n}\n40% {\n        stroke: #0057e7;\n}\n66% {\n        stroke: #008744;\n}\n80%,\n    90% {\n        stroke: #ffa700;\n}\n}\n"
),
  (n.render = function (n, t, r, s, i, o) {
    return (
      e.openBlock(),
      e.createBlock(
        "span",
        { class: "lv-progress-spinner", role: "alert", "aria-busy": "true", style: { display: "inline-block", height: r.size, width: r.size } },
        [
          (e.openBlock(),
            e.createBlock(
              "svg",
              { class: "lv-progress-spinner-svg", viewBox: "25 25 50 50", style: o.svgStyle },
              [
                e.createVNode("circle", { class: "lv-progress-spinner-circle", cx: "50", cy: "50", r: "20", fill: r.fill, "stroke-width": r.strokeWidth, style: o.animStyle, strokeMiterlimit: "10" }, null, 12, [
                  "fill",
                  "stroke-width",
                ]),
              ],
              4
            )),
        ],
        4
      )
    );
  }),
  (module.exports = n);
