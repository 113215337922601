<template>
  <div class="vesting-page">
    <h1>
      {{ $t("vesting.title1") }}
    </h1>
    <h2>
      {{ $t("vesting.title2") }}
    </h2>
    <div class="advantages-card card">
      <img
        src="/img/vesting/vesting-man.png"
        alt="vesting"
      >
      <div class="advantages-list">
        <div class="advantages-item">
          <img
            src="/img/vesting/advantage-1.svg"
            alt="advantage"
          >
          <span>
            {{ $t("vesting.advantages.1") }}
          </span>
        </div>
        <div class="advantages-item">
          <img
            src="/img/vesting/advantage-2.svg"
            alt="advantage"
          >
          <span>
            {{ $t("vesting.advantages.2") }}
          </span>
        </div>
        <div class="advantages-item">
          <img
            src="/img/vesting/advantage-3.svg"
            alt="advantage"
          >
          <span>
            {{ $t("vesting.advantages.3") }}
          </span>
        </div>
      </div>
    </div>
    <div class="cards-line">
      <div class="smart-contract-card card">
        <h3>
          {{ $t("vesting.smartContact.title") }}
        </h3>
        <div class="address-wrapper">
          <h4>
            {{ $t("vesting.smartContact.codeLabel") }}:
          </h4>
          <div class="line" />
        </div>
        <div class="secure-check">
          <img
            src="/img/vesting/MythX_logo.svg"
            alt="MythX_logo"
          >
        </div>
      </div>
      <div class="wallet-card card">
        <h3>
          {{ `${$t("vesting.wallet.wallet")} ${vestingShort}` }}
          <span>AUT</span>
          <img
            src="/img/vesting/aut-logo.svg"
            alt="aut-logo"
          >
        </h3>
        <div class="vesting-profit">
          <div class="stats">
            <div>
            <span>
              {{ $t("vesting.wallet.accruedPerPeriod") }}
            </span>
              <span>
              {{ currentMonthShort }} AUT
            </span>
            </div>
            <div>
            <span>
              {{ $t("vesting.wallet.accruedAll") }}
            </span>
              <span>
              {{ profit }} AUT
            </span>
            </div>
          </div>
          <div class="next-unblocking">
            <p>
              {{ `${$t("vesting.wallet.anotherUnblocking")} ${nextUnlock.date}:` }}
            </p>
              <span>
              {{ unlockPercent }} AUT
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-card card">
      <h3>
        {{ $t("vesting.schedule.title") }}
      </h3>
      <div class="schedule-table">
        <div
          v-for="(item, index) in schedule"
          :key="index"
          class="table-row"
        >
          <div class="table-col">
            <span>
              {{ `${item.date} UTC` }}
            </span>
          </div>
          <div class="table-col">
            <div class="unblocked">
              {{ `${$t("vesting.schedule.unblockingLabel")}: ${item.unblocked} AUT (${item.percent}%)` }}
            </div>
          </div>
        </div>
        <div class="table-footer">
          <p>
            {{ `100% ${$t("vesting.schedule.unblockingPersent")}, ` }}
            <span>
              {{ $t("vesting.schedule.vestingProgress") }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import auth from "@/auth";
import axios from "axios";
import BigNumber from "bignumber.js";
import { computed, onMounted, ref } from "vue";

const vestingBalance = ref(0);
const currentMonthPercent = ref(0);
const profit = ref(0);
let schedule = ref([]);
let allTransactions = [];

const vestingDate = {
  roundOne: {
    amount: 0,
    start: 1699228800000,
    end: 1700524800000,
    shed: [
      { date: "20.03.2024", percent: 20, days: 31 },
      { date: "20.04.2024", percent: 20, days: 30 },
      { date: "20.05.2024", percent: 20, days: 31 },
      { date: "20.06.2024", percent: 20, days: 30 },
      { date: "20.07.2024", percent: 20, days: 31 }
    ]
  },
  preRound: {
    amount: 0,
    start: 1697760000000,
    end: 1699228800000,
    shed: [
      { date: "05.05.2024", percent: 20, days: 31 },
      { date: "05.06.2024", percent: 20, days: 30 },
      { date: "05.07.2024", percent: 20, days: 31 },
      { date: "05.08.2024", percent: 20, days: 31 },
      { date: "05.09.2024", percent: 20, days: 30 }
    ]
  },
  closedPreSale: {
    amount: 0,
    start: 1693526400000,
    end: 1697760000000,
    shed: [
      { date: "30.05.2024", percent: 20, days: 31 },
      { date: "30.06.2024", percent: 20, days: 30 },
      { date: "30.07.2024", percent: 20, days: 31 },
      { date: "30.08.2024", percent: 20, days: 31 },
      { date: "30.09.2024", percent: 20, days: 30 }
    ]
  },
  roundTwo: {
    amount: 0,
    start: 1700524800000,
    end: 1711929600000,
    shed: [
      { date: "15.06.2024", percent: 20, days: 30 },
      { date: "15.07.2024", percent: 20, days: 31 },
      { date: "15.08.2024", percent: 20, days: 31 },
      { date: "15.09.2024", percent: 20, days: 30 },
      { date: "15.10.2024", percent: 20, days: 31 }
    ]
  },
  adviser: {
    amount: 0,
    start: 1711929600000,
    end: 1714435200000,
    shed: [
      { date: "30.09.2024", percent: 20, days: 30 },
      { date: "30.10.2024", percent: 20, days: 31 },
      { date: "30.11.2024", percent: 20, days: 30 },
      { date: "30.12.2024", percent: 20, days: 31 },
      { date: "30.01.2025", percent: 20, days: 31 }
    ]
  },
  promotion: {
    amount: 0,
    start: 0,
    end: 0,
    shed: [
      { date: "15.05.2024", percent: 100, days: 31 }
    ]
  },
  team: {
    amount: 0,
    start: 0,
    end: 0,
    shed: [
      { date: "15.03.2025", percent: 2.5, days: 31 },
      { date: "15.04.2025", percent: 2.5, days: 30 },
      { date: "15.05.2025", percent: 2.5, days: 31 },
      { date: "15.06.2025", percent: 2.5, days: 30 },
      { date: "15.07.2025", percent: 2.5, days: 31 },
      { date: "15.08.2025", percent: 2.5, days: 31 },
      { date: "15.09.2025", percent: 2.5, days: 30 },
      { date: "15.10.2025", percent: 2.5, days: 31 },
      { date: "15.11.2025", percent: 2.5, days: 30 },
      { date: "15.12.2025", percent: 2.5, days: 31 },
      { date: "15.01.2026", percent: 2.5, days: 31 },
      { date: "15.02.2026", percent: 2.5, days: 28 },
      { date: "15.03.2026", percent: 5, days: 31 },
      { date: "15.04.2026", percent: 5, days: 30 },
      { date: "15.05.2026", percent: 5, days: 31 },
      { date: "15.06.2026", percent: 5, days: 30 },
      { date: "15.07.2026", percent: 5, days: 31 },
      { date: "15.08.2026", percent: 5, days: 31 },
      { date: "15.09.2026", percent: 10, days: 30 },
      { date: "15.10.2026", percent: 10, days: 31 },
      { date: "15.11.2026", percent: 10, days: 30 },
      { date: "15.12.2026", percent: 10, days: 31 }
    ]
  }
};

const nextUnlock = computed(() => {
  const currentDate = new Date();
  const ret = _.find(schedule.value, item => {
    const day = item.date.slice(0, 2);
    const month = item.date.slice(3, 5);
    const year = item.date.slice(6);
    const compareDate = new Date(year, month - 1, day);
    return currentDate <= compareDate;
  });
  return ret ?? { date: "-", percent: 0 };
});

const unlockPercent = computed(() => {
  return BigNumber(vestingBalance.value).dividedBy(100)
    .multipliedBy(nextUnlock.value.percent).toFixed(2);
});

const vestingShort = computed(() => {
  return BigNumber(vestingBalance.value).toFixed(2);
});

const currentMonthShort = computed(() => {
  const ret = BigNumber(currentMonthPercent.value);
  return ret.isNaN() ? "0.00" : ret.toFixed(2);
});


onMounted(async () => {
  await getData();
  await getTransactions();
  calcProfit();
  calcSchedule();
});

const getData = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const userResp = await axios.get(`/api/users/${authMem?.id}`, {
      headers: headers
    });
    vestingBalance.value = userResp.data.vestingBalance;
    currentMonthPercent.value = userResp.data?.autoStakingPercentsBalance;
  }
  catch (e) {
    console.log(e);
  }
};

const getTransactions = async () => {
  try {
    const authMem = JSON.parse(auth.remember());
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authMem?.token}`
    };
    const params = {
      userId: authMem?.id,
      isPaymentConfirmed: true
    };
    const resp = await axios.get("/api/exchange_pays", {
      headers: headers,
      params: params
    });
    allTransactions = resp.data;
  }
  catch (e) {
    console.log(e);
  }
};

const calcProfit = () => {
  const currentDate = new Date();
  let addition = BigNumber(0), balance = BigNumber(0);

  for (let i = 0; i < allTransactions.length; i++) {
    balance = balance.plus(allTransactions[i]?.toVestingBalance);

    const created_at = Date.parse(allTransactions[i]?.createdAt);
    calcVestingAmount(created_at, allTransactions[i]?.toVestingBalance);

    let days = i + 1 === allTransactions.length ?
      currentDate : Date.parse(allTransactions[i + 1]?.createdAt);
    days = Math.floor((days - created_at) / 86400000);

    addition = addition.plus(balance.multipliedBy(days).dividedBy(3000));
  }
  profit.value = addition.toFixed(2);
};

const calcVestingAmount = (createdDate, amount) => {
  for (const name in vestingDate) {
    if (createdDate >= vestingDate[name].start && createdDate < vestingDate[name].end) {
      vestingDate[name].amount = BigNumber(vestingDate[name].amount).plus(amount).toFixed(2);
      return;
    }
  }
};

const calcSchedule = () => {
  for (const name in vestingDate) {
    if (vestingDate[name].amount == 0) {
      continue;
    }
    schedule.value = [...schedule.value, ..._.map(vestingDate[name].shed, item => {
      const balance = BigNumber(vestingDate[name].amount);
      return {
        date: item.date,
        unblocked: balance.dividedBy(100).multipliedBy(item.percent).toFixed(2),
        accruals: balance.multipliedBy(item.days).dividedBy(3000).toFixed(2),
        percent: item.percent
      };
    })];
  }
  schedule.value = _.sortBy(schedule.value, el => {
    const dateEl = new Date(el.date.slice(6), el.date.slice(3, 5) - 1, el.date.slice(0, 2));
    return +dateEl;
  });
};
</script>

<style lang="scss" scoped>
.vesting-page {
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
  padding-right: 2.5vw;
  padding-bottom: 4.2vw;
  h1 {
    color: #1F3A43;
    font-weight: 600;
    font-size: 3.5vw;
    line-height: 1.2;
  }
  h2 {
    color: #1F3A43;
    font-weight: 600;
    font-size: 2.0vw;
    line-height: 1.2;
  }
  .card {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 53px 43px 45px 0 rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;
    padding-right: 20px;
  }
  .advantages {
    &-card {
      display: flex;
      align-items: center;
      height: 28.23vw;
      gap: 3vw;
      img[alt="vesting"] {
        width: 29vw;
        height: 28vw;
        align-self: flex-end;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 0.8vw;
    }
    &-item {
      display: flex;
      gap: 0.5vw;
      align-items: center;
      img {
        width: 7vw;
        height: 7vw;
      }
      span {
        font-family: Inter SemiBold;
        line-height: 1.7vw;
        font-size: 0.9vw;
        max-width: 20vw;
      }
    }
  }
  .cards-line {
    gap: 2.5vw;
    display: grid;
    grid-template-columns: 48% 48%;
    .smart-contract-card,
    .wallet-card {
      padding: 1.7vw;
      h3 {
        font-family: Inter SemiBold;
        font-size: 1.3vw;
        line-height: 1.2;
      }
      h4 {
        font-family: Inter SemiBold;
        font-size: 1.1vw;
        line-height: 1.2;
      }
    }
    .smart-contract-card {
      display: flex;
      flex-direction: column;
      gap: 2vw;
      .address-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        font-family: Inter SemiBold;
        p {
          font-size: 0.84vw;
          color: #1F3A43;
          a {
            color: #0C6D57;
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background: #DAEDF3;
          margin: 1.5vw 0;
        }
      }
      .secure-check {
        display: flex;
        align-items: center;
        gap: 1.5vw;
        img {
          height: 5vw;
          width: 13vw;
        }
        span {
          font-family: Inter SemiBold;
          font-size: 1.1vw;
          line-height: 1.2;
        }
      }
    }
    .wallet-card {
      display: flex;
      flex-direction: column;
      gap: 1.8vw;
      h3 {
        display: flex;
        gap: 0.3vw;
        span {
          font-family: Inter;
        }
      }
      .stats {
        display: flex;
        flex-direction: column;
        gap: 1.5vw;
        div {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 0.94vw;
            &:last-child {
              font-family: Inter SemiBold;
            }
          }
        }
      }
      .next-unblocking {
        background-color: #1F3A43;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.94vw;
        height: 3.23vw;
        span {
          font-family: Inter SemiBold;
          color: #4FE9A6;
        }
      }
      .send-request {
        display: flex;
        gap: 1vw;
        img {
          width: 3.48vw;
        }
        .btn {
          display: flex;
          align-items: center;
          gap: 0.5vw;
          .arrow {
            font-size: 1.15vw;
          }
        }
      }
    }
  }
  .schedule {
    &-card {
      display: flex;
      flex-direction: column;
      gap: 2.5vw;
      padding: 2.1vw 2.1vw 2.6vw;
      color: #1F3A43;
      h3 {
        font-family: Inter SemiBold;
        font-size: 1.98vw;
      }
    }
    &-table {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
      font-size: 0.94vw;
      font-family: Inter SemiBold;

      .table-row {
        display: flex;
        gap: 0.5vw;
        height: 4.32vw;
        &:nth-child(odd) {
          .table-col {
            background: #F2FAFC;
          }
        }
        &:nth-child(even) {
          .table-col {
            background: #E2EEF2;
          }
        }
      }
      .table-col {
        padding: 0 1.04vw;
        width: 50%;

        display: flex;
        &:first-child {
          align-items: center;
          justify-content: flex-start;
        }
        &:last-child {
          flex-direction: column;
          align-items: end;
          justify-content: center;
          font-family: Inter Medium;
          gap: 0.4vw;
        }
      }
      .table-footer {
        display: flex;
        height: 4.32vw;
        justify-content: center;
        align-items: center;
        background-color: #1F3A43;
        border-radius: 0 0 10px 10px;

        p {
          font-size: 0.94vw;
          color: white;
          font-family: Inter SemiBold;
          span {
            color: #4FE9A6;
            font-family: Inter Medium;
          }
        }
      }
    }
  }
}
.vesting-profit {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
