<template>
  <div class="s-main">
    <div class="s-address">
      <span>
        {{ addressTo }}
      </span>
      <div>
        <SuccessMark
          v-show="copied"
          :show-animate="copied"
        />
        <img
          v-if="!copied"
          src="/img/modal/copy.svg"
          alt="copy"
          @click="copyAddress"
        >
      </div>
    </div>
    <div class="s-explain s-additional">
      <span v-html="$t('bridge.step2.sendToken')" />
    </div>
    <div class="s-important s-additional">
      <span v-html="$t('bridge.step2.important')" />
    </div>
  </div>
</template>

<script setup>
import SuccessMark from "@/components/SuccessMark.vue";
import { ref } from "vue";

const props = defineProps({
  addressTo: {
    type: String,
    default: ""
  }
});

const copied = ref(false);

const copyAddress = () => {
  navigator.clipboard.writeText(props.addressTo).then(() => {
    copied.value = true;
    setTimeout(function() {
      copied.value = false;
    }, 1700);
  });
};
</script>

<style scoped lang="scss">
.s-main {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1000px) {
    width: 600px;
  }

  @media screen and (max-width: 600px) {
    width: 300px;
  }
}
.s-address {
  display: inline-flex;
  padding: 21px 32px;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 10px;
  background: #c1f9e0;

  margin: 20px 0;

  color: #096f57;
  font-family: Inter Bold;
  font-size: 26px;

  span {
    @media screen and (max-width: 1200px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  img {
    width: 48px;
    height: 48px;
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 8px;
    font-size: 12px;
    gap: 5px;
    width: 230px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}
.s-additional {
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
}
.s-explain {
  margin-top: 20px;
}
.s-important {
  margin-top: 40px;
}
</style>
