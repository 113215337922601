<template>
  <div
    v-click-outside="vcoConfig"
    class="product"
    @click="showPopper = true"
  >
    <div class="product-wrapper">
      <div class="image-wrapper">
        <div class="image-wrap image">
          <img
            :src="`/img/products/${product.img}.png`"
          >
        </div>
      </div>
      <h4>
        {{ product.name }}
      </h4>
      <span>
        {{ product.text }}
      </span>
    </div>

    <img
      class="arrow"
      :src="`/img/products/arrow.svg`"
    >
  </div>

  <div
    v-if="showPopper"
    class="popper-wrap"
  >
    <div class="popper">
      {{ product.more }}
    </div>
  </div>

  <div
    v-if="showPopper"
    class="backdrop"
  />
</template>

<script setup>
import _ from "lodash";
import { computed } from "vue";

import { ref } from "vue";

defineProps({
  product: {
    type: Object,
    default: () => {}
  }
});

const showPopper = ref(false);

const middleware = event => {
  return !_.some(event.composedPath(), elem => elem.className && elem.className == "popper");
};

const handler = () => {
  showPopper.value = false;
};

const vcoConfig = computed(() => ({
  handler: handler,
  middleware: middleware
}));

</script>

<style lang="scss" scoped>
.popper {
  border-radius: 20px;
  border: #3eb682 1px solid;

  padding: 30px;

  background: #FFF;
  color: #1F3A43;

  font-size: 17px;
  line-height: 26px;

  overflow: auto;
  width: 50%;
  max-height: 80vh;

  &-wrap {
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0px;
    left: 0px;

    z-index: 5000;

    width: 100%;
    height: 100%;
  }
}

.backdrop {
  background: rgba(9, 30, 66, 0.54);
  height: 100%;
  width: 100vw;
  z-index: 4000;
  position: absolute;
  left: 0px;
  top: 0px;
}

.image-wrap {
  height: 100%;
  position: absolute;
  display: flex;
  align-content: baseline;
  justify-content: center;
  width: 100%;
  align-items: center;
  z-index: 100;

  &.image {
    z-index: 100;
    top: -15px;
    left: -5px;

    img {
      width: 100vw;
    }
  }

  &.circle {
    img {
      width: 100vw;
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 440px;
  height: 680px;
  padding: 40px 30px 45px 30px;
  align-items: flex-start;

  border-radius: 20px;
  border: 1px #FFF solid;

  background: #FFF;
  box-shadow: 53px 43px 45px 0px rgba(210, 237, 235, 0.20), 30px 26px 26px -5px rgba(210, 237, 235, 0.86), 5px 4px 6px 0px #D2EDEB;

  @media screen and (max-width: 1900px) {
    width: 340px;
    height: 750px;
  }

  @media screen and (max-width: 800px) {
    max-width: 70vw;
    height: 100%;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &:hover {
    border-color: #4FE9A6;
    transition: border-color 1s;
  }

  .arrow {
    align-self: flex-end;
    cursor: pointer;
  }

  .image-wrapper {
    position: relative;
    height: 330px;
    width: 100%;
  }
  h4 {
    font-size: 28px;
    color: #1F3A43;
    font-weight: bold;
  }
  span {
    color: #1F3A43;
    font-size: 17px;
    line-height: 26px;
  }
}
</style>
