function isTokenSaleClose() {
  const dot1 = process.env.VUE_APP_CLOSED.indexOf(".");
  const dot2 = process.env.VUE_APP_CLOSED.lastIndexOf(".");
  const checkDate = new Date(Date.UTC(process.env.VUE_APP_CLOSED.slice(dot2 + 1),
    process.env.VUE_APP_CLOSED.slice(dot1 + 1, dot2) - 1,
    process.env.VUE_APP_CLOSED.slice(0, dot1), 0, 0, 0));
  const nowDate = new Date();
  return nowDate >= checkDate;
}
export default isTokenSaleClose;
