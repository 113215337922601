<template>
  <header class="header container">
    <img
      class="logo"
      src="/img/logo.svg"
    >

    <div class="right-bar">
      <Socials type="dark" />

      <AuSelect
        css-vars="--width: 160px"
        class="locale-switcher"
        :value="currentLocale"
        :placeholder="currentLocale.toUpperCase()"
        :options="countryOptions"
        :image="true"
        @input="(v) => switchLanguage(v)"
      />

      <div class="cabinet-link">
        <img src="/img/cabinet/briefcase.svg">
        <div class="link-info">
          <span>{{ $t("header.cabinet") }}</span>
          <span
            class="sign"
            @click="goToLogin"
          >{{ $t("header.signIn") }}</span>
        </div>

        <div class="link-info_mobile">
          →
          <span
            class="sign"
            @click="goToLogin"
          >{{ $t("header.cabinet") }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import _ from "lodash";

import Socials from "@/components/Socials.vue";

import { useI18n } from "vue-i18n";
const { t } = new useI18n();

import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import Trans from "@/localization/translation";

const supportedLocales = ref(Trans.supportedLocales);

const router = useRouter();

const currentLocale = computed(() => {
  return Trans.currentLocale;
});
const countryOptions = computed(() => {
  return _.map(supportedLocales.value, l => {
    return {
      display: l.toUpperCase(),
      value: l
    };
  });
});
document.title = t("title");

const switchLanguage = async newLocale => {
  await Trans.switchLanguage(newLocale);

  try {
    await router.replace({ params: { locale: newLocale } });
    document.title = t("title");
  }
  catch (e) {
    console.log(e);
    router.push("/");
  }
};

const goToLogin = () => {
  router.push({ name: "Login", params: { locale: Trans.currentLocale } });
};
</script>
<style lang="scss">
.locale-switcher {
  .multiselect {
    border: none;

    justify-content: center;

    &__content-wrapper {
      @media screen and (max-width: 800px) {
        max-width: fit-content !important;
        left: 25px !important;
      }
    }

    &__tags {
      margin-bottom: 10px;

      @media screen and (max-width: 800px) {
        padding-right: 15px !important;
        padding-top: 11px !important;
      }
    }

    &__select {
      right: 18px;
      top: 5px;
    }
    &__single {
      color: #096f57;
      font-size: 20px;

      @media screen and (max-width: 800px) {
        font-size: 13px !important;
      }

      div {
        display: flex;
        align-items: center;
        color: #1F3A43;

        .picture {
          margin-right: 10px;
          height: 28px;

          @media screen and (max-width: 800px) {
            height: 18px !important;
            margin-right: 5px !important;
          }
        }
      }
    }
    &__input {
      font-size: 20px;
      padding-left: 53px;
      padding-top: 6px;
      color: transparent;

      @media screen and (max-width: 800px) {
        font-size: 13px !important;
        padding-left: 76px !important;
        padding-top: 4px !important;
      }
    }
    &__content {
      &-wrapper {
        top: 50px;
        left: 0;
      }
    }
    &__element {
      font-size: 20px;
      padding-left: 0;
      .picture {
        height: 28px;
      }

      @media screen and (max-width: 800px) {
        font-size: 13px !important;

        .picture {
          height: 20px;
        }
      }
    }
    &__option {
      padding: 7px 22px;
    }
  }
}
</style>
<style lang="scss" scoped>
.header {
  @media screen and (max-width: 1200px) {
    margin-bottom: 0px;
  }

  .logo {
    @media screen and (max-width: 800px) {
      height: 25px;
    }
  }

  .right-bar {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  padding-top: 31px;

  @media screen and (max-width: 800px) {
    padding: 15px 15px 0 15px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cabinet-link {
  display: flex;
  gap: 15px;
  align-items: center;
  color: #2ABA7C;

  z-index: 1000;

  @media screen and (max-width: 800px) {
    gap: 5px;

    img {
      width: 18px;
    }
  }

  .link-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;

    z-index: 1000;

    @media screen and (max-width: 800px) {
      display: none;
    }

    &_mobile {
      display: none;

      font-size: 13px;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }
  }
  .sign {
    color: #2ABA7C;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
