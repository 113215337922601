<template>
  <div class="scs-main">
    <img
      class="scs-decoration"
      src="/img/system/decoration.svg"
      alt=""
    >
    <div class="scs-content">
      <div class="scs-text">
        <span class="scs-title">
          {{ $t("headerSection.saleClose") }}
        </span>
        <span class="scs-subtitle">
          {{ $t("tokenClose.listingStock") + ` ${listing}` }}
        </span>
      </div>
      <CloseClock />
    </div>
  </div>
</template>

<script setup>
import CloseClock from "@/components/CloseClock";
import { computed } from "vue";

const listing = computed(() => {
  return process.env.VUE_APP_LISTING;
});
</script>

<style scoped lang="scss">
.scs-main {
  position: relative;
  overflow-y: hidden;

  height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.scs-decoration {
  position: absolute;
  left: -100px;
  top: 50px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 800px) {
    left: -50px;
  }
}
.scs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 10;
}
.scs-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.scs-title {
  font-size: 50px;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}
.scs-subtitle {
  font-size: 40px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
}
</style>
