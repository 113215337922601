<template>
  <section class="referral-section container">
    <div class="section-main-content-wrapper">
      <div class="first-column">
        <h1> {{ $t("referralSection.header") }} </h1>
        <p>{{ $t("referralSection.title") }}</p>
        <p>
          {{ $t("referralSection.text") }}
        </p>
        <h3>{{ $t("referralSection.info") }}</h3>
      </div>
      <div class="second-column">
        <img
          src="/img/referral/money.svg"
          alt="money"
        >
      </div>
    </div>
    <div class="scheme-wrapper">
      <ReferralStep
        v-for="(referralStep, index) in referralSteps"
        :key="index"
        :image="referralStep.image"
        :text="referralStep.text"
        :is-last="index == referralSteps.length - 1"
      />
    </div>
    <div class="buttons">
      <AuButton
        padding="15px 50px"
        @click="goToRegister"
      >
        {{ $t("referralSection.buttons.partner") }}
      </AuButton>
      <a
        class="more-info"
        href="#"
        @click="openRefProgram"
      >{{ $t("referralSection.buttons.details") }}</a>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = new useI18n();

import ReferralStep from "@/components/ReferralStep.vue";
import { useRouter } from "vue-router";

const referralSteps = computed(() => {
  return [
    {
      image: "first-step",
      text: t("referralSection.steps.first")
    },
    {
      image: "second-step",
      text: t("referralSection.steps.second")
    },
    {
      image: "third-step",
      text: t("referralSection.steps.third")
    }
  ];
});

const openRefProgram = () => {
  window.open("https://docs.autentic.capital/article/15771", "_blank");
};

const router = useRouter();
const goToRegister = () => {
  router.push({ name: "Register" });
};
</script>

<style lang="scss" scoped>
.referral-section {
  padding-top: 100px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;

  .section-main-content-wrapper {
    display: flex;
    margin-bottom: 23px;
    gap: 50px;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    .first-column {
      h1 {
        max-width: 1034px;
        color: #1f3a43;
        font-size: 68px;
        font-weight: bold;
        margin-bottom: 26px;
        line-height: 1.2;
      }
      p {
        color: #1f3a43;
        line-height: 38px;
        max-width: 927px;
        &:nth-child(2) {
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        &:nth-child(3) {
          font-size: 28px;
          font-weight: 500;
          margin-bottom: 65px;
        }
      }
      h3 {
        color: #1f3a43;
        line-height: 38px;
        font-size: 38px;
        font-weight: 600;
        margin-bottom: 40px;
      }
    }
    .second-column {
      img {
        width: 489px;
        height: 563.147px;
      }
    }
  }
  .scheme-wrapper {
    display: flex;
    gap: 34px;
    margin-bottom: 40px;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .buttons {
    display: flex;
    gap: 25px;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-bottom: 80px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    a.more-info {
      font-weight: 600;
      line-height: 30px;
      font-size: 28px;
      color: #2aba7c;
      text-decoration: none;
      &::after {
        content: url("../../public/img/referral/mini-arrow.svg");
        margin-left: 8px;
      }
    }
  }

  .subtext {
    width: 100%;
    .line {
      width: 99px;
      height: 4px;
      background: #4fe9a6;
      margin-bottom: 30px;
    }
    h3 {
      color: #1f3a43;
      font-size: 32px;
      font-weight: bold;
      line-height: 42px;
      max-width: 987px;
    }
  }
}
</style>
