<template>
  <button
    type="button"
    class="btn"
    :disabled="disabled"
    :shadow="shadow"
    :transparent="transparent"
    :style="cssVars"
    :center="center"
    :active="active"
    @click="emit('click')"
  >
    <SpinnerLoader
      v-if="isProgress"
      color="#5AC396"
      :size="loaderHeight"
    />
    <AuIcon
      v-if="icon && !isProgress"
      :icon="icon"
    />
    <div
      v-if="$slots['default'] && !isProgress"
      class="btn__text"
    >
      <slot />
    </div>
    <AuIcon
      v-if="iconAfter && !isProgress"
      :icon="iconAfter"
    />
  </button>
</template>

<script setup>
import SpinnerLoader from "@/loaders/progress-spinner";

import { computed, ref, useSlots } from "vue";

const slots = useSlots();

const props = defineProps({
  icon: {
    type: String,
    default: ""
  },
  iconAfter: {
    type: String,
    default: ""
  },
  width: {
    type: String,
    default: ""
  },
  height: {
    type: String,
    default: ""
  },
  center: {
    type: Boolean,
    default: false
  },
  backgroundColor: {
    type: String,
    default: "#2ABA7C"
  },
  borderColor: {
    type: String,
    default: "#2ABA7C"
  },
  activeBgColor: {
    type: String,
    default: "#2ABA7C"
  },
  hoveredBgColor: {
    type: String,
    default: "#18d88d69"
  },
  active: {
    type: Boolean,
    default: false
  },
  fontSize: {
    type: String,
    default: "28px"
  },
  fontFamily: {
    type: String,
    default: "Inter"
  },
  isProgress: {
    type: Boolean,
    default: false
  },
  textColor: {
    type: String,
    default: "white"
  },
  textColorActive: {
    type: String,
    default: "#5ac396"
  },
  padding: {
    type: String,
    default: "23px 65px"
  },
  shadow: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  transparent: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["click"]);

const basicWidth = ref("auto");
const basicHeight = ref("auto");

const cssVars = computed(() => {
  if (props.disabled) {
    return {
      "--width": currentWidth.value,
      "--height": currentHeight.value,
      "--background-color": "#d0d0d0",
      "--border-color": "#d0d0d0",
      "--background-active-color": "#d0d0d0",
      "--background-hovered-color": "#d0d0d0",
      "--font-size": props.fontSize,
      "--font-family": props.fontFamily,
      "--text-color": props.textColor,
      "--text-color-active": "white",
      "--padding": props.padding
    };
  }
  else {
    return {
      "--width": currentWidth.value,
      "--height": currentHeight.value,
      "--background-color": props.backgroundColor,
      "--border-color": props.borderColor,
      "--background-active-color": props.activeBgColor,
      "--background-hovered-color": props.hoveredBgColor,
      "--font-size": props.fontSize,
      "--font-family": props.fontFamily,
      "--text-color": props.textColor,
      "--text-color-active": props.textColorActive,
      "--padding": props.padding
    };
  }
});
const onlyIcon = computed(() => {
  return slots.default == null;
});
const currentWidth = computed(() => {
  if (props.width) {
    return props.width;
  }
  else if (onlyIcon.value) {
    return "auto";
  }

  return basicWidth.value;
});
const currentHeight = computed(() => {
  if (props.height) {
    return props.height;
  }
  else if (props.onlyIcon) {
    return "auto";
  }

  return basicHeight.value;
});
const loaderHeight = computed(() => {
  return props.height ? props.height : "34px";
});
</script>

<style scoped lang="scss">
button[width-limit] {
  max-width: 10px;
  margin-right: -6px;
}

button.btn {
  padding: var(--padding);
  background: none;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  width: var(--width);
  height: var(--height);

  border: var(--border-color) 1.5px solid;
  border-radius: 10px;

  &[active="true"] {
    border-color: var(--background-active-color);
  }

  &[transparent="true"] {
    background: none;
    border: #2aba7c 1.5px solid;

    .btn__text {
      color: #2aba7c;
    }
  }

  &[shadow="true"] {
    background: #2aba7c;
    box-shadow: 0px 15px 14px 0px rgba(160, 182, 194, 0.56);
  }

  .btn__text {
    color: var(--text-color);
    font-size: var(--font-size);
    font-family: var(--font-family);
    white-space: nowrap;
  }

  &[active="true"] {
    background-color: var(--background-active-color);
    transition: background-color 0.5s ease;
    .btn__text {
      color: white;
      transition: color 0.5s ease;
      font-weight: 450;
    }
  }

  &:hover,
  &:active {
    background-color: var(--background-hovered-color);
    transition: background-color 0.5s ease;

    .btn__text {
      color:  var(--text-color-active);
      transition: color 0.5s ease;
    }
  }

  &[center="true"] {
    justify-content: center;
  }
}
</style>
