<template>
  <div
    class="select-wrapper"
    :style="cssVars"
  >
    <VueMultiselect
      :model-value="currentValue"
      :placeholder="placeholder"
      :options="options"
      :track-by="trackBy"
      :label="label"
      :show-labels="false"
      :allow-empty="allowEmpty"
      class="select"
      :disabled="disabled"
      :class="{ 'req-error': required && reqError }"
      @update:model-value="input"
    >
      <!-- <template #caret="{ toggle }">
        <AuIcon
          icon="chevron_up"
          class="select__chevron-icon"
          @mousedown.prevent.stop="toggle()"
        />
      </template> -->

      <template
        v-if="image"
        #singleLabel="{ option }"
      >
        <div class="d-flex align-items-center">
          <img
            :src="option.image"
            class="picture"
          >
          {{ option.display }}
        </div>
      </template>

      <template
        v-if="image"
        #option="{ option }"
      >
        <div class="d-flex align-items-center">
          <img
            :src="option.image"
            class="picture"
          >
          {{ option.display }}
        </div>
      </template>

      <template
        v-if="clearable && value"
        #clear="props"
      >
        <div
          class="multiselect__clear"
          @mousedown.prevent.stop="clear(props)"
        >
          ×
        </div>
      </template>

      <template #noResult>
        Ничего не найдено
      </template>
      <template #noOptions>
        Ничего нет
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";

import _ from "lodash";
// import AuIcon from "./AuIcon.vue";

export default {
  name: "AuSelect",

  components: {
    VueMultiselect
  },

  props: {
    placeholder: {
      type: String,
      default: ""
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    // массив вида [{display: "testDisplay", value: "testValue"}]
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Boolean, Number],
      default: ""
    },
    trackBy: {
      type: String,
      default: "value"
    },
    label: {
      type: String,
      default: "display"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    cssVars: {
      type: String,
      default: "--width: 100%"
    }
  },

  emits: ["input"],

  computed: {
    currentValue() {
      return _.find(this.options, option => option[this.trackBy] == this.value);
    },

    reqError() {
      return _.isEmpty(this.currentValue);
    }
  },

  methods: {
    input(value) {
      this.$emit("input", value === null ? this.currentValue?.value : value?.value);
    },
    clear() {
      this.$emit("clearValue");
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.picture {
  height: 32px;
  margin-right: 5px;
}
.multiselect {
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid #4fe9a6;
  width: var(--width);
  height: calc(100% - 4px);
  display: flex;
  align-items: center;
  &__element {
    font-size: 28px;
    color: #096f57;
    padding-left: 12px;

    &:hover {
      background: #096f5717;
    }
  }
  &__select {
    right: 10px;
    top: 22px;
  }
  &__tags {
    color: white;
  }
  &--disabled {
    background: none;
    opacity: 1;
    .multiselect__tags {
      border: 1px solid var(--color-40) !important;
      .multiselect__placeholder {
        color: var(--color-40);
      }
    }
    .icon.select__chevron-icon {
      display: none;
    }
  }
  &.req-error {
    .multiselect__tags {
      border: 1px solid #ff000069;
    }

    .multiselect__content-wrapper {
      border-color: #ff000069;
    }
  }

  .select__chevron-icon {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 10px;
    top: 3px;
    text-align: center;
    padding-top: 13px;
    color: #505f79;
    font-size: 28px;
  }

  &__tags {
    background: #fff0;
    height: 41px;
    border: 1px solid var(--color-70);
    border-radius: var(--border-radius);
    width: auto;
    height: 44px;
    padding: 8px 40px 0px 20px;
  }

  &__content-wrapper {
    background: white;
    border-color: #4fe9a6;
    border: 2px solid #4fe9a6;
    color: black;
    top: 70px;
    width: 100%;
    left: -2px;
    width: calc(100% + 4px);
    border-top: none;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #4fe9a6;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: #f9f9fd;
    }
  }

  &__placeholder {
    color: #096f57;
    margin-bottom: 0;
    font-size: 28px;
  }

  &__input,
  &__single {
    background: #fff0;
    font-size: 28px;
    color: var(--text-color);
    top: 3px;
  }

  &__input {
    margin-left: -5px;
    margin-top: -4px;
  }

  &__clear {
    position: absolute;
    font-size: 13px;
    right: 40px;
    top: calc(50% - 19.5px / 2);
    z-index: 10;
    color: var(--color-60);
  }

  &__tag {
    color: var(--text-color);
    margin: 2px;
    padding: 2px 26px 2px 4px;

    &-icon {
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 17px;

      &,
      &:focus,
      &:hover,
      &:active {
        &:after {
          color: #0052cc;
        }
      }

      &:focus {
        background: inherit;
      }

      &:hover {
        background: #d1e5ff;
      }

      &:active {
        background: #caddf8;
      }
    }
  }

  &__option {
    line-height: 1;
    padding: 7px 9px;
    min-height: auto;
    font-size: inherit;
    color: var(--text-color);
    font-weight: inherit;

    &:hover {
      background: var(--color-90);
    }

    &:active {
      background: #ccdffa;
    }

    &--highlight {
      background: var(--color-90);
      color: inherit;
    }

    &--selected {
      background: #deebff;
      color: #000000;

      &.multiselect__option--highlight {
        background: #ccdffa;
        color: inherit;
      }

      &:hover {
        background: #ccdffa;
      }
    }
  }
}
.au-select-label {
  font-size: 14px;
  margin-bottom: 15px;
  color: var(--color-60);
  &[disabled="true"] {
    color: var(--color-40);
  }
}
</style>
