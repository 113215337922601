<template>
  <section class="container">
    <iframe
      height="33vw"
      width="100%"
      src="https://www.youtube.com/embed/5NRXB8K4I2Q?&autoplay=1&mute=1"
      frameborder="0"
      allowfullscreen
    />
  </section>
</template>

<style lang="scss" scoped>
.container {
  position: relative;

  background: #000000;
  padding: 100px 20vw;

  display: flex;
  justify-content: center;

  iframe {
    height: 33vw;
  }

  @media screen and (max-width: 1200px) {
    padding: 10vw;
  }

  @media screen and (max-width: 800px) {
    iframe {
      height: 50vw;
    }
  }
}
</style>