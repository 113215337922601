<template>
  <div class="b-main">
    <img
      class="b-logo"
      src="/img/logo.svg"
      alt="Logo"
    >
    <div class="b-title">
      {{ $t("bridge.step1.title") }}
    </div>
    <div class="b-change">
      <img
        class="b-img"
        src="/img/bridge/binance_logo.svg"
        alt="binance"
      >
      <div class="b-line"/>
      <div class="b-arrow"/>
      <img
        class="b-img"
        src="/img/bridge/ton_logo.svg"
        alt="ton"
      >
    </div>
    <Step1
      v-if="showStep1"
      @next="showNext"
    />
    <Step2
      v-else
      :addressTo="addressTo"
    />
  </div>
</template>

<script setup>
import Step1 from "@/components/bridge/Step1";
import Step2 from "@/components/bridge/Step2";
import { ref } from "vue";

const showStep1 = ref(true);
const addressTo = ref("");

const showNext = params => {
  addressTo.value = params.addressTo;
  showStep1.value = !params.isSuccess;
};
</script>

<style scoped lang="scss">
.b-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #daedf3 -2.62%, #fefeff 77.25%, #fff 77.25%);
  height: 100vh;
  overflow-y: scroll;
}
.b-logo {
  margin-top: 10px;
  width: 100px;
}
.b-title {
  font-size: 28px;
  font-weight: 500;
  margin-top: 30px;
}
.b-img {
  width: 60px;
}
.b-change {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.b-line {
  border-top: 2px solid #4fe9a6;
  width: 400px;
  margin-left: 15px;
  @media screen and (max-width: 1000px) {
    width: 300px;
  }
  @media screen and (max-width: 600px) {
    width: 100px;
  }
}
.b-arrow {
  border-top: 2px solid #4fe9a6;
  border-right: 2px solid #4fe9a6;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);

  margin-right: 15px;
}
</style>
