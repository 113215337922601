<template>
  <div class="vip-page">
    <h1>
      {{ $t('vip.plug') }}
    </h1>
  </div>
</template>

<script setup>

</script>

<style lang="scss">
.vip-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  h1 {
    font-size: 2vw;
  }
}
</style>