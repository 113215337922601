<template>
  <div class="buy-aut">
    <img
      class="decor"
      src="/img/cabinet/decor.svg"
      alt=""
    >
    <div class="title">
      {{ $t("buyAutBlock.buyTitle") }}
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.round") }}
      </div>
      <div class="right">
        {{ $t("buyAutBlock.name") }}
      </div>
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.available") }}
      </div>
      <div class="right">
        {{ total5000 }} {{ $t("buyAutBlock.from") }} 100000
      </div>
    </div>
    <AuButton
      class="buy-btn"
      width="176px"
      height="62px"
      center
      font-size="22px"
      @click="openModal"
    >
      {{ $t("buyAutBlock.buyButton") }}
    </AuButton>
    <div class="divider">
      &nbsp;
    </div>
    <div class="title">
      {{ $t("buyAutBlock.balanceTitle") }}
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.total") }}
      </div>
      <div class="right">
        {{ total }} AUT
      </div>
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.on") }} <span class="green">{{ $t("buyAutBlock.wallet") }}</span>
      </div>
      <div class="right">
        {{ main }} AUT
      </div>
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.onBalance") }} <span class="green">{{ $t("buyAutBlock.vesting") }}</span>
      </div>
      <div class="right">
        {{ vesting }} AUT
      </div>
    </div>
    <div class="line">
      <div class="left">
        <span class="green">{{ $t("buyAutBlock.staking") }}</span> {{ $t("buyAutBlock.balance") }}:
      </div>
      <div class="right">
        {{ staking }} AUT
        <AuPopper :text="$t('buyAutBlock.stakingQuestion')">
          <img
            class="clickable"
            src="/img/cabinet/question.svg"
            alt=""
          >
        </AuPopper>
      </div>
    </div>
    <div class="line">
      <div class="left">
        <span class="green">{{ $t("buyAutBlock.referral") }}</span> {{ $t("buyAutBlock.balance") }}:
      </div>
      <div class="right">
        {{ referral }} AUT
        <AuPopper :text="$t('buyAutBlock.referralQuestion')">
          <img
            class="clickable"
            src="/img/cabinet/question.svg"
            alt=""
          >
        </AuPopper>
      </div>
    </div>
    <div class="line">
      <div class="left">
        <span class="green">{{ $t("buyAutBlock.referral") }}</span> {{ $t("buyAutBlock.balance") }} (USDT):
      </div>
      <div class="right">
        {{ usdtReferral }} USDT
        <AuPopper :text="$t('buyAutBlock.referralQuestion')">
          <img
            class="clickable"
            src="/img/cabinet/question.svg"
            alt=""
          >
        </AuPopper>
      </div>
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.nextRound") }}
      </div>
      <div class="right">
        0 AUT
        <AuPopper :text="$t('buyAutBlock.nextRoundQuestion')">
          <img
            class="clickable"
            src="/img/cabinet/question.svg"
            alt=""
          >
        </AuPopper>
      </div>
    </div>
    <div class="line">
      <div class="left">
        {{ $t("buyAutBlock.in") }} <span class="green">{{ $t("buyAutBlock.invest") }}</span>
      </div>
      <div class="right">
        0 AUT
        <AuPopper :text="$t('buyAutBlock.investQuestion')">
          <img
            class="clickable"
            src="/img/cabinet/question.svg"
            alt=""
          >
        </AuPopper>
      </div>
    </div>
  </div>
</template>

<script setup>
import auth from "@/auth";
import BigNumber from "bignumber.js";
import isTokenSaleClose from "@/help/sale";
import TokenClose from "@/components/TokenClose";
import TokensBuy from "@/components/TokensBuy";
import TokensBuyOld from "@/components/TokenBuyOld";
import { computed, defineProps, inject } from "vue";
import { useModal } from "vue-final-modal";

const props = defineProps({
  mainBalance: {
    type: String,
    default: "0"
  },
  referralBalance: {
    type: String,
    default: "0"
  },
  usdtReferralBalance: {
    type: String,
    default: "0"
  },
  vestingBalance: {
    type: String,
    default: "0"
  },
  autoStakingBalance: {
    type: String,
    default: "0"
  },
  address: {
    type: String,
    default: "0"
  }
});

const total = computed(() => {
  return BigNumber(props.mainBalance).plus(props.referralBalance)
    .plus(props.vestingBalance).plus(props.autoStakingBalance).toFixed(2);
});
const total5000 = computed(() => {
  return BigNumber(100000).minus(props.mainBalance)
    .minus(props.vestingBalance).toFixed(2);
});

const main = computed(() => {
  return BigNumber(props.mainBalance).plus(props.autoStakingBalance).toFixed(2);
});

const vesting = computed(() => {
  return BigNumber(props.vestingBalance).toFixed(2);
});

const staking = computed(() => {
  return BigNumber(props.autoStakingBalance).toFixed(2);
});

const referral = computed(() => {
  return BigNumber(props.referralBalance).toFixed(2);
});

const usdtReferral = computed(() => {
  return BigNumber(props.usdtReferralBalance).toFixed(2);
});

const userName = computed(() => {
  let ret = "";
  try {
    ret = JSON.parse(auth.remember());
  }
  catch (e) {
    return "";
  }
  return ret?.username ? ret.username : "";
});

const groupAB = inject("groupAB");
const openModal = () => {
  let component = groupAB == 1 ? TokensBuy : TokensBuyOld;
  component = isTokenSaleClose() ? TokenClose : component;
  const { open, close } = useModal({
    component: component,
    attrs: {
      initEmail: userName.value,
      initAddress: props.address,
      onClose() {
        close();
      }
    }
  });
  open();
};
</script>

<style lang="scss" scoped>
.buy-aut {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: white;
  padding: 40px;
  border-top: 6px solid #4FE9A6;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  button.buy-btn {
    width: 100% !important;
  }

  .title {
    font-weight: 600;
    font-size: 2vw;
    margin-bottom: 30px;

    @media (max-width: 1600px) {
      font-size: 2.5vw;
    }

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  .green {
    color: #2ABA7C;
  }

  .line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    font-size: 1vw;
    margin-bottom: 24px;
    border-bottom: 1px dashed black;

    @media (max-width: 1600px) {
      font-size: 1.5vw;
    }

    @media (max-width: 800px) {
      font-size: 20px;
    }

    .left {
      font-weight: 400;
    };

    .right {
      display: flex;
      gap: 5px;
      font-weight: 600;

      text-align: end;
    }
  }
}
.divider {
  width: 100%;
  background-image: url("/public/img/cabinet/ellipse.svg");
  margin: 50px 0;
}
.decor {
  position: absolute;
  right: 0;
  bottom: 0;
}
.clickable {
  z-index: 999;
  cursor: pointer;
}
</style>
