<template>
  <footer class="footer">
    <div class="container">
      <span class="footer-text">© 2022 - {{ currentYear }},&nbsp;
        <a
          href="/Certificate.pdf"
          target="_blank"
        >«Autentic LTD»</a></span>

      <div class="cards">
        <img src="img/footer/mastercard.svg">
        <img src="img/footer/visa.svg">
      </div>

      <Socials />
    </div>
  </footer>
</template>

<script setup>
import Socials from "@/components/Socials.vue";

import { computed } from "vue";

const currentYear = computed(() => new Date().getFullYear());
</script>

<style lang="scss" scoped>
.footer {
  min-height: 147px;
  background: linear-gradient(117deg, #162c34 31.73%, #1f3a43 67.07%);
  display: flex;
  align-items: center;

  &-text {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    a {
      color: white;
      font-size: 19px;
      text-decoration: none;
      border-bottom: 1px solid white;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 0px;
    margin-right: 50px;
  }

  .cards  {
    display: flex;
    gap: 25px;
  }
}
</style>
