<template>
  <div class="lc-background">
    <div class="lc-panel">
      <span class="lc-text">
        {{ message }}
      </span>
      <AuInput
        v-model="login"
      />
      <AuButton
        center
        @click="checkLogin"
      >
        {{ $t("loginCheck.check") }}
      </AuButton>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n({});
let login = ref(null);
let message = ref(t("loginCheck.input"));

const checkLogin = async () => {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json"
  };
  const params = {
    email: login.value
  };
  try {
    await axios.post("/api/users/check", params, { headers });
    message.value = t("loginCheck.free");
  }
  catch (e) {
    message.value = t("loginCheck.busy");
  }
};
</script>

<style scoped>
.lc-background {
  background: #1F3A43;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.lc-panel {
  margin-top: 150px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.lc-text {
  font-size: 30px;
  color: white;
}
</style>
